import styled from "styled-components";

export const Main = styled.div`
 

  .containers {
    display: flex;
   height: 100vh;
    min-height: 100%;
  }

  .fixed-image {
    position: fixed;
    left: 0;
    top: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  .fixed-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .main-content {
    width: 50%;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    @media screen and (max-width: 768px) {
      width: 100%;
     
    }
  }
  .sub-main-content {
    padding: 20px 60px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 992px) {
      padding: 20px 30px;
    }
  
   
  }

  .yellow_border {
    border-bottom: 5px solid #ffbb02;

    @media screen and (max-width: 768px) {
      padding-top: 10px;
    }
  }

  .input,
  .input-password PhoneInput {
    width: 100%;
    height: 45px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    color: black;
    padding: 9px;
    outline: #ffbb02;
  }

  .SignUp_Button {
    background-color: #0c3a2d;
    color: white;
    width: 100%;
    border-radius: 6px;
    height: 45px;
    margin-top: 14px;
  }
  .first-last-name {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    @media screen and (max-width: 500px) {
      min-width: 100%;
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  .select-gender {
    width: 100%;
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }

  .dob-input {
    width: 100%;
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
  .gender-dob{
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    @media screen and (max-width: 500px) {
      min-width: 100%;
      flex-wrap: wrap;
      gap: 0px;
    }
  }

  .last-name-input,
  .first-name-input {
    width: 100%;
    @media screen and (max-width: 500px) {
      width: 100%;
    }
  }
  .phone-no {
    width: 100%;
  }

  .err-message {
    padding: 0;
    margin: -0px 0px -2px 0px;
    font-size: 12px;
  }
  label{
    padding: 0px !important;
    margin-bottom: 0px;
    margin-top: 2px;
    font-size: 14px;
    font-weight: 500;
  }

  @media screen and (max-width: 500px) {
    input {
      width: 100%;
    }
  }
`;
