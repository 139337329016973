import { DataApi } from "../../api";
import { toast } from "react-toastify"; // Import the toast library

const usehook = () => {
  const signupDetail = async (params) => {
    try {
      const response = await DataApi.postData(params);
      return response; 
    } catch (error) {
      console.error("Full error object:", error);
      throw error; // Re-throw the error if needed for further handling
    }
  };

  return {
    signupDetail,
  };
};

export default usehook;
