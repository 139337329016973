import styled from "styled-components";
import iconBg from "../../images/workesSection/icon_bg.png";
export const WorkesSectionStyle = styled.div`
  /* -------------How_It_Works-Section-Css-Start------------------ */

  /* how it works wraper */
  .how_it_works .container {
    max-width: 100%;
  }

  .how_it_works .how_it_inner {
    background-color: var(--bg-white);
    padding: 70px 0;
    border-radius: 30px;
    box-shadow: 0px 4px 30px #73a59742;
  }

  /* how it works list */
  .how_it_works .step_block ul {
    max-width: 1080px;
    margin: 0 auto;
    padding: 40px;
  }

  .how_it_works .step_block ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 50px;
  }

  .how_it_works .step_block ul li::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    width: 4px;
    height: calc(100% + 100px);
    background-color: #20705A;
  }
  .step_number h3{
    color: #0C3A2D;
  }

  .how_it_works .step_block ul li:first-child::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    width: 14px;
    height: 14px;
    background-color: #20705A;
    border-radius: 15px;
  }

  .how_it_works .step_block ul li:first-child::before {
    top: 0;
  }

  .how_it_works .step_block ul li:last-child::before {
    height: 50%;
    top: 0;
  }

  .how_it_works .step_block ul li .step_text,
  .how_it_works .step_block ul li .step_img {
    width: 300px;
    text-align: right;
  }

  /* how it works image */
  .how_it_works .step_block ul li .step_img img {
    max-width: 100%;
  }

  /* how it works heading h4 */
  .how_it_works .step_block ul li .step_text h4 {
    font-size: 20px;
    font-weight: 600;
  }

  .how_it_works .step_block ul li .step_text .app_icon {
    margin-bottom: 10px;
  }

  .how_it_works .step_block ul li .step_text .app_icon a {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: var(--light-bg);
    color: var(--text-white);
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    transition: 0.4s all;
    margin: 0px 2px;
  }

  .how_it_works .step_block ul li .step_text .app_icon a:hover {
    background-color: var(--red);
  }

  .how_it_works .step_block ul li .step_text span {
    font-weight: 600;
  }

  .how_it_works .step_block ul li .step_text span a {
    color: var(--purple);
    text-decoration: underline;
  }

  .how_it_works .step_block ul li:nth-child(2) {
    flex-direction: row-reverse;
  }

  .how_it_works .step_block ul li:nth-child(2) .step_text,
  .how_it_works .step_block ul li:nth-child(2) .step_img {
    text-align: left;
  }
  .how_it_works .step_block ul li:nth-child(3) {
    /* flex-direction: row-reverse; */
  }

  .how_it_works .step_block ul li:nth-child(3) .step_text,
  .how_it_works .step_block ul li:nth-child(3) .step_img {
    text-align: right;
  }
  .how_it_works .step_block ul li:nth-child(4) {
    flex-direction: row-reverse;
  }

  .how_it_works .step_block ul li:nth-child(4) .step_text,
  .how_it_works .step_block ul li:nth-child(4) .step_img {
    text-align: left;
  }
  .how_it_works .step_block ul li:nth-child(5) {
    /* flex-direction: row-reverse; */
  }

  .how_it_works .step_block ul li:nth-child(5) .step_text,
  .how_it_works .step_block ul li:nth-child(5) .step_img {
    text-align: right;
  }

  .how_it_works .step_block ul li:last-child {
    flex-direction: row-reverse;
  }

  .how_it_works .step_block ul li:last-child .step_text,
  .how_it_works .step_block ul li:last-child .step_img {
    text-align: left;
  }

  /* how it works numbers */
  .how_it_works .step_block ul li .step_number {
    background-image: url(${iconBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  /* how it works numbers heading h3 */
  .how_it_works .step_block ul li .step_number h3 {
    font-size: 30px;
    font-weight: 600;
  }

  .text-clr {
    color: var(--red) !important;
  }

  /* ------Media-Query-Start--------- */
  @media screen and (max-width: 1200px) {
    .row_am {
      padding: 50px 0;
    }
  }

  @media screen and (max-width: 992px) {
    /* how it work section */
    .how_it_works .step_block ul li .step_text,
    .how_it_works .step_block ul li .step_img {
      width: 280px;
    }
    .how_it_works .yt_video .thumbnil a {
      top: 57%;
    }
    .how_it_works .yt_video .thumbnil a span {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 767px) {
    body {
      font-size: 14px;
      text-align: center;
    }
    .row_am {
      padding: 30px 0;
    }
    .section_title p br {
      display: none;
    }
    .free_app_section .container .free_app_inner .free_text .section_title h2,
    .section_title h2 {
      font-size: 24px;
    }

    /* how it work section */
    .how_it_works .how_it_inner {
      padding: 50px 15px 5px 15px;
    }
    .how_it_works .step_block ul {
      padding-top: 30px;
    }
    .how_it_works .step_block ul li,
    .how_it_works .step_block ul li:nth-child(2) {
      flex-direction: column;
      padding-left: 30px;
    }
    .how_it_works .step_block ul li,
    .how_it_works .step_block ul li:nth-child(3) {
      flex-direction: column;
      padding-left: 30px;
    }
    .how_it_works .step_block ul li,
    .how_it_works .step_block ul li:nth-child(4) {
      flex-direction: column;
      padding-left: 30px;
    }
    .how_it_works .step_block ul li,
    .how_it_works .step_block ul li:nth-child(5) {
      flex-direction: column;
      padding-left: 30px;
    }
    .how_it_works .step_block ul li,
    .how_it_works .step_block ul li:last-child {
      flex-direction: column;
      padding-left: 30px;
    }
    .how_it_works .step_block ul li .step_text h4 {
      font-size: 18px;
    }
    .how_it_works .step_block ul li::before {
      left: 0;
      transform: none;
      height: calc(100% + 10px);
      top: 40px;
    }
    .how_it_works .step_block ul li:first-child::before {
      height: calc(100% + 50px);
    }
    .how_it_works .step_block ul li:first-child::after {
      left: -5px;
      transform: none;
      display: none;
    }
    .how_it_works .step_block ul li .step_text,
    .how_it_works .step_block ul li .step_img,
    .how_it_works .step_block ul li:nth-child(2) .step_text,
    .how_it_works .step_block ul li:nth-child(2) .step_img {
      text-align: center;
    }
    .how_it_works .step_block ul li .step_text,
    .how_it_works .step_block ul li .step_img,
    .how_it_works .step_block ul li:nth-child(3) .step_text,
    .how_it_works .step_block ul li:nth-child(3) .step_img {
      text-align: center;
    }
    .how_it_works .step_block ul li .step_text,
    .how_it_works .step_block ul li .step_img,
    .how_it_works .step_block ul li:nth-child(4) .step_text,
    .how_it_works .step_block ul li:nth-child(4) .step_img {
      text-align: center;
    }
    .how_it_works .step_block ul li .step_text,
    .how_it_works .step_block ul li .step_img,
    .how_it_works .step_block ul li:nth-child(5) .step_text,
    .how_it_works .step_block ul li:nth-child(5) .step_img {
      text-align: center;
    }
    .how_it_works .step_block ul li .step_text,
    .how_it_works .step_block ul li .step_img,
    .how_it_works .step_block ul li:nth-child(6) .step_text,
    .how_it_works .step_block ul li:nth-child(6) .step_img {
      text-align: center;
    }

    .how_it_works .step_block ul li .step_text,
    .how_it_works .step_block ul li .step_img {
      width: 100%;
    }
    .how_it_works .step_block ul li .step_img img {
      max-width: 75%;
    }
    .how_it_works .step_block ul li .step_number {
      position: absolute;
      top: -5px;
      left: -23px;
      width: 50px;
      height: 50px;
    }
    .how_it_works .step_block ul li:last-child::before {
      opacity: 0;
    }
    .how_it_works .step_block ul li .step_number h3 {
      font-size: 15px;
      margin-bottom: 0;
      margin-top: -2px;
    }
    .how_it_works .yt_video {
      margin-top: -50px;
    }
    .how_it_works .yt_video .thumbnil {
      height: 300px;
      border-radius: 15px;
    }
    .how_it_works .yt_video .thumbnil img {
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 15px;
    }
    .how_it_works .yt_video .thumbnil a {
      width: 100%;
    }
    .how_it_works .yt_video .thumbnil a .play_btn::after {
      width: 140px;
      height: 140px;
    }
    .how_it_works .yt_video .thumbnil a .play_btn::before {
      width: 120px;
      height: 120px;
    }
    .how_it_works .yt_video .thumbnil a .play_btn img {
      height: auto;
    }
  }
  @media screen and (max-width: 576px) {
    .how_it_works .step_block ul li .step_img img {
      max-width: 80%;
    }
  }

  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1170px;
    }
  }
`;
