import React, { useEffect, useState } from "react";
import { DownloadSectionStyle } from "./style";
import appstoreBlue from "../../images/downloadSection/appstorewhite.png";
import gogleplayBlue from "../../images/downloadSection/googlebtnwhite.png";

import mobilebanerone from "../../images/downloadSection/mobilebane1.png";
import mobilebanertwo from "../../images/downloadSection/mobilebaner2.png";

const DownloadSection = () => {
  const [isBackdropVisible, setIsBackdropVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("getstarted");
      const rect = section.getBoundingClientRect();
      const sectionCenter = rect.top + rect.height / 2;
      const viewportCenter = window.innerHeight / 2;

      // Show backdrop if the section center is in the viewport center
      setIsBackdropVisible(
        Math.abs(sectionCenter - viewportCenter) < rect.height / 2
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  return (
    <DownloadSectionStyle>
      {/* Download-Free-App-section-Start  */}
      <div
        className={`purple_backdrop ${isBackdropVisible ? "visible" : ""}`}
      ></div>
      <section className="row_am free_app_section" id="getstarted">
        {/* container start */}

        <div className="container">
          <div
            className="free_app_inner"
            data-aos="fade-in"
            data-aos-duration={1500}
            data-aos-delay={100}
          >
            {/* row start */}
            <div className="row">
              {/* content */}
              <div className="col-md-6">
                <div className="free_text">
                  <div className="section_title">
                    <h2>Let’s download free from apple and play store</h2>
                    <p>
                    Download our app for free from the Apple App Store or Google Play Store. Enjoy easy access to all our features and services right at your fingertips
                    </p>
                  </div>
                  <ul className="app_btn">
                    <li>
                      <a href="#">
                        <img
                          src={appstoreBlue}
                          alt="image"
                          className="appstore-btn"
                        />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img
                          src={gogleplayBlue}
                          alt="image"
                          className="appstore-btn"
                        />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* images */}
              <div className="col-md-6">
                <div className="free_img">
                  <img src={mobilebanertwo} alt="image" className="imgOne" />
                  <img
                    className="mobile_mockup imgTwo"
                    src={mobilebanerone}
                    alt="image"
                  />
                </div>
              </div>
            </div>
            {/* row end */}
          </div>
        </div>
        {/* container end */}
      </section>
      {/* Download-Free-App-section-end  */}
    </DownloadSectionStyle>
  );
};

export default DownloadSection;
