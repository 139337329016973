import React, { useEffect, useRef } from "react";

import herbaner1 from "../../images/bannersectionImages/herobaner1.png";
import herbaner2 from "../../images/bannersectionImages/herobaner2.png";
import herbaner3 from "../../images/bannersectionImages/herobaner3.png";
import herbaner4 from "../../images/bannersectionImages/herobaner4.png";
import bannerhersplash from "../../images/bannersectionImages/splash.png";
import appstoreblue from "../../images/bannersectionImages/appstoreblue.png";
import googlebtnblue from "../../images/bannersectionImages/googlebtnblue.png";
import downloadicon from "../../images/download.png";
import followersicon from "../../images/followers.png";
import reviewsicon from "../../images/reviews.png";
import countriesicon from "../../images/countries.png";
 import Spiner from "../spinner";

import playicon from "../../images/play_icon.png";

import { BannerStyle } from "./style";

const BannerSection = () => {
  const sectionRef = useRef(null);

  useEffect(() => {
    const counters = document.querySelectorAll(".counter-value");

    const updateCounter = (element, countTo) => {
      let count = 0;
      const step = () => {
        count += Math.ceil(countTo / 100); // Increase count in small steps
        if (count >= countTo) {
          count = countTo;
          clearInterval(interval);
        }
        element.textContent = count;
      };
      const interval = setInterval(step, 100);
    };

    const handleScroll = () => {
      if (sectionRef.current) {
        const rect = sectionRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if (rect.top <= windowHeight * 0.8) {
          counters.forEach((counter) => {
            const countTo = parseInt(counter.getAttribute("data-count"), 10);
            updateCounter(counter, countTo);
          });
          window.removeEventListener("scroll", handleScroll); 
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check in case the section is already in view

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <BannerStyle>
      {/* Banner-Section-Start */}
      <section className="banner_section" id="home">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            <div
              className="col-lg-8 col-md-12 mx-auto"
              data-aos="flip-up"
              data-aos-duration={1500}
            >
              {/* banner text */}
              <div className="banner_text">
                {/* h1 */}
                <h1>Best way to curate your perfect desired space.</h1>
                {/* p */}
                <p>
                LotusPro is the ultimate platform for furniture lovers to discover, design, and enhance their living spaces. With effortless browsing and a variety of interactive features, LotusPro connects you with the furniture community like never before.
                </p>
              </div>
              {/* video start */}
            
              <div
                className="yt_video"
                data-aos="fade-top"
                data-aos-duration={1500}
              >
                <Spiner/>
              </div>
              {/* video end */}
            </div>
          </div>
          {/* row end */}
        </div>
        {/* container end */}
        {/* banner images start */}
        <div
          className="container-fluid"
          data-aos="fade-in"
          data-aos-duration={1500}
        >
          <div className="banner_images">
            <div className="row d-flex justify-content-center">
              {/* screen 1 */}
              <div className="col-md-2 col-lg-2 col-sm-4 col-xs-4 d-none d-lg-block">
                <div className="banner_screen screen1">
                  <img
                    className="moving_position_animatin"
                    src={herbaner1}
                    alt="image"
                  />
                </div>
              </div>
              {/* screen 2 */}
              <div className="col-md-4 col-lg-2 col-sm-4 col-xs-4">
                <div className="banner_screen screen2 ">
                  <img
                    className="moving_animation"
                    src={herbaner2}
                    alt="image"
                  />
                </div>
              </div>
              {/* screen 3 */}
              <div className="col-md-4 col-lg-3 col-sm-4 col-xs-4">
                <div className="banner_screen screen3">
                  <img
                    className="moving_position_animatin bg-danger"
                    src={bannerhersplash}
                    alt="image"
                  />
                </div>
              </div>
              {/* screen 4 */}
              <div className="col-md-4 col-lg-2 col-sm-4 col-xs-4">
                <div className="banner_screen screen4">
                  <img
                    className="moving_animation"
                    src={herbaner3}
                    alt="image"
                  />
                </div>
              </div>
              {/* screen 5 */}
              <div className="col-md-2 col-lg-2 col-sm-4 col-xs-4 d-none d-lg-block">
                <div className="banner_screen screen5">
                  <img
                    className="moving_position_animatin"
                    src={herbaner4}
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* banner images end */}
      </section>
      {/* Banner-Section-end */}
      {/* Download button  Section start */}
      <section className="row_am download_section">
        {/* container start */}
        <div className="container">
          {/* icon start */}
          <ul className="app_btn" data-aos="fade-in" data-aos-duration={1500}>
            <li>
              <a href="#" className="app_store">
                <img className="blue_img" src={appstoreblue} alt="image" />
              </a>
             
            </li>
            <li>
              <a href="#" className="app_store">
                <img className="blue_img" src={googlebtnblue} alt="image" />
                {/* <img className="white_img" src={googlebtnwhite} alt="image" /> */}
              </a>
             
            </li>
          </ul>
          {/* icon  end */}
        </div>
      
        {/* container end */}
      </section>
      {/* Download button Section Section ends */}
      {/* statistic section start */}
      {/* <section className="row_am statistic_section" ref={sectionRef}>
        <div className="container">
          <ul className="app_statstic" id="counter">
            <div
              className="col-md-3 col-lg-3 col-sm-6 col-xs-12"
              data-aos="fade-up"
              data-aos-duration={1000}
            >
              <li>
                <div className="icon">
                  <img src={downloadicon} alt="image" />
                </div>
                <div className="text">
                  <p>
                    <span className="counter-value text-color" data-count={17}>
                      0
                    </span>
                    <span className="text-color">M+</span>
                  </p>
                  <p>Download</p>
                </div>
              </li>
            </div> 
            <div
              className="col-md-3 col-lg-3 col-sm-6 col-xs-12"
              data-aos="fade-up"
              data-aos-duration={1200}
            >
              <li>
                <div className="icon">
                  <img src={followersicon} alt="image" />
                </div>
                <div className="text">
                  <p>
                    <span className="counter-value text-color" data-count={8}>
                      0
                    </span>
                    <span className="text-color">M+</span>
                  </p>
                  <p>Followers</p>
                </div>
              </li>
            </div>
            <div
              className="col-md-3 col-lg-3 col-sm-6 col-xs-12"
              data-aos="fade-up"
              data-aos-duration={1400}
            >
              <li>
                <div className="icon">
                  <img src={reviewsicon} alt="image" />
                </div>
                <div className="text">
                  <p>
                    <span
                      className="counter-value text-color"
                      data-count={2300}
                    >
                      0
                    </span>
                    <span className="text-color">+</span>
                  </p>
                  <p>Reviews</p>
                </div>
              </li>
            </div>
            <div
              className="col-md-3 col-lg-3 col-sm-6 col-xs-12"
              data-aos="fade-up"
              data-aos-duration={1600}
            >
              <li>
                <div className="icon">
                  <img src={countriesicon} alt="image" />
                </div>
                <div className="text">
                  <p>
                    <span className="counter-value text-color" data-count={150}>
                      0
                    </span>
                    <span className="text-color">+</span>
                  </p>
                  <p>Countries</p>
                </div>
              </li>
            </div>
          </ul>
        </div>
      </section> */}
      {/* statistic section ends */}
    </BannerStyle>
  );
};

export default BannerSection;
