import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BeautifullInterfaceStyle } from './style';
import sliderimg1 from "../../images/sliderimages/sliderimg1.png";
import sliderimg2 from "../../images/sliderimages/sliderimg2.png";
import sliderimg3 from "../../images/sliderimages/sliderimg3.png";
import sliderimg4 from "../../images/sliderimages/sliderimg4.png";
import sliderimg5 from "../../images/sliderimages/sliderimg5.png";




const BeautifullInterfaceSection = () => {
  const settings = {
    centerMode: true,
    centerPadding: '40px',
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 1000,
    dots: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          centerPadding: '0px',
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <BeautifullInterfaceStyle>
      {/* Beautifull-interface-Section start */}
      <section className="row_am interface_section">
        <div className="container-fluid">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1000}
            data-aos-delay={100}
          >
            <h2>
              Beautifull <span>interface</span>
            </h2>
            <p>
            Experience a beautifully designed interface that sets a new standard <br/>  for visual appeal. Our app integrates sophisticated design with  <br/> a modern touch, enhancing your overall interaction.
            </p>
          </div>
          {/* screen slider start */}
          <div className="screen_slider">
            <Slider {...settings}>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={sliderimg1} alt="screen-1" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={sliderimg2} alt="screen-2" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={sliderimg3} alt="screen-3" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={sliderimg4} alt="screen-4" />
                </div>
              </div>
              <div className="item">
                <div className="screen_frame_img">
                  <img src={sliderimg5} alt="screen-5" />
                </div>
              </div>
            </Slider>
          </div>
          {/* screen slider end */}
        </div>
      </section>
      {/* Beautifull-interface-Section end */}
    </BeautifullInterfaceStyle>
  );
};

export default BeautifullInterfaceSection;
