import styled from "styled-components";
import heroBg from "../../images/herobg.png";
export const FooterSectionStyle = styled.div`
  /* ------Footer-Css-Start-------------- */
  /* footer wraper */
  footer {
    position: relative;
  }

  footer .top_footer {
    background-image: url(${heroBg});
    background-size: cover;
    padding: 180px 0 60px 0;
    position: relative;
    overflow: hidden;
  }

  footer .top_footer .container {
    position: relative;
  }

  /* footer logo */
  footer .top_footer .logo {
    margin-bottom: 55px;
  }

  footer .top_footer .logo img {
    width: 150px;
  }

  footer .top_footer .abt_side li {
    padding: 0 0 10px 0;
  }

  /* footer social media icon */
  footer .top_footer .social_media {
    display: flex;
    margin-top: 20px;
  }

  /* footer link list */
  footer .top_footer .social_media li a {
    display: block;
    width: 37px;
    height: 37px;
    text-align: center;
    line-height: 37px;
    font-size: 15px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 50px;
    margin-right: 10px;
    transition: 0.4s all;
  }


  footer .top_footer .social_media li a:hover {
    background-color: var(--bg-white);
    color: var(--red);
  }

  footer .top_footer .try_out {
    margin-left: -20px;
  }

  footer .app_btn li a {
    display: block;
    /* padding: 12px 10px; */
    /* background-color: var(--bg-white); */
    position: relative;
    border-radius: 12px;
    transition: 0.4s all;
    width: 175px;
    text-align: center;
  }
  .app_btn li a .appstore-btn{
    width: 160px;

  }

  /* footer .app_btn li a:hover {
    -webkit-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 4px 8px -2px rgba(0, 0, 0, 0.5);
  } */

  footer .app_btn li:last-child {
    margin-top: 20px;
  }

  footer .bottom_footer {
    background-color: #0C3A2D;
  }

  /* footer heading and text colors variable */
  footer h2,
  footer h3,
  footer p,
  footer a {
    color: var(--text-white);
  }

  footer a:hover {
    color: var(--text-white);
  }

  /* footer heading h3 */
  footer h3 {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 50px;
    padding-top: 10px;
  }

  footer .links ul li a {
    display: block;
    margin-bottom: 10px;
  }

  footer .links ul li a:hover {
    color: var(--red);
    transition: 0.4s all;
  }

  /* footer last */
  footer .bottom_footer {
    padding: 20px 0;
  }

  footer .bottom_footer p {
    margin-bottom: 0;
    font-size: 15px;
  }

  footer .bottom_footer .developer_text {
    text-align: right;
  }

  footer .bottom_footer .developer_text a {
    text-decoration: underline;
  }

  
  /* footer go top button */

  /* ------Media-Query-Start--------- */
 

  @media screen and (max-width: 1200px) {
    .row_am {
      padding: 50px 0;
    }


    



  }

  @media screen and (max-width: 992px) {
  
    /* footer section */
    footer .top_footer .logo,
    footer h3 {
      margin-bottom: 20px;
    }
    footer .abt_side {
      margin-bottom: 50px;
    }
    footer .top_footer .try_out {
      margin-left: 0;
    }
    footer .top_footer .col-md-6 {
      margin-bottom: 15px;
    }
  }

  @media screen and (max-width: 767px) {
    body {
      font-size: 14px;
      text-align: center;
    }
    .row_am {
      padding: 30px 0;
    }
  

   

    /* footer section */
    footer .links,
    footer .abt_side {
      margin-bottom: 30px;
    }
    footer .top_footer .social_media {
      justify-content: center;
    }
    footer .top_footer .social_media li a {
      margin: 0 5px;
    }
    footer .top_footer .try_out {
      margin-left: 0;
    }
    footer .app_btn li a {
      margin: 0 auto;
    }
    footer .bottom_footer .developer_text {
      text-align: center;
      margin-top: 10px;
    }
    footer .go_top {
      right: 10px;
    }
    footer .top_footer .col-md-6 {
      margin-bottom: 0px;
    }

  }

  @media screen and (min-width:1200px) {
    .container {
        max-width: 1170px;
    }
}
`;
