import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import "./App.css";

import MainPage from "./container/mainPage";
import TermsCondition from "./container/termCondition";
import PrivacyPolicy from "./container/privacyPolicy";
import ProductDetail from "./container/productDetail";
import SignUp from "./container/signUp";
import BlankPage from "./container/bankPage";
function App() {
 
  return (
    <>
     
   
      <Routes>
        <Route path="/" Component={MainPage} />
        <Route path="/term" Component={TermsCondition} />
        <Route path="/policy" Component={PrivacyPolicy} />
        <Route path="/signup" Component={SignUp}/>
        <Route path="/product/:id" Component={ProductDetail}/>
        <Route path="/blank-page" Component={BlankPage}/>

      </Routes>

  
    </>
  );
}

export default App;
