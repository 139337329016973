import React, { useState } from "react";
import { Main } from "./style";
import { useForm } from "react-hook-form";
import signupimg from "../../images/signupimg.png";
import usehook from "./usehook";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SignUp = () => {
  const [loading, setLoading] = useState(false); // State to manage loading
  const [phoneNo, setPhoneNo] = useState(""); // State to manage phone input

  const navigate = useNavigate();
  const gotoHome = ()=>{
    navigate("/")
  }
  const goToblank = ()=>{
    navigate("/blank-page")
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    trigger,
  } = useForm();

  const { signupDetail } = usehook();

  const onSubmit = async (data) => {
    setLoading(true); // Start loading
    const signupdata = {
      name: data.firstName + " " + data.lastName,
      email: data.email,
      gender: data.gender,
      dob: data.dob,
      countryCode: data.countryCode,
      phone: data.phone,
      isoCode: data.isoCode, 
      profile_pic: "public/uploads/images/77fd3fc9-b186-49fb-a982-4670f5afbca9-desk.png",
    };

    try {
   const response =  await signupDetail(signupdata); // Call the API and wait for response
     console.log(response) 
     if (response.status === true) {
        toast.success(response?.message)
        reset();
        setPhoneNo("")
        setTimeout(() => {
          navigate("/blank-page");
        }, 2000); 
        
     } else {
        toast.error(response?.error)
     }
    } catch (error) {
      console.error("Signup failed:", error); // Log error if needed
    } finally {
      setLoading(false); // Stop loading
    }
    console.log(data);
  };

  return (
    <Main>
      <div className="containers">
        <div className="fixed-image">
          <img src={signupimg} alt="Decorative" />
        </div>

        <div className="main-content">
          <div className="sub-main-content">
            <div>
              <div>
                <p className="yellow_border"></p>
              </div>
              <div>
                <div className="margin">
                  <h4 className="heading">Register As A Vendor</h4>
                  <p className="welcome_Content">Please enter your details</p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="to">
                    <div className="first-last-name">
                      <div className="first-name-input mt-1">
                        <label>First Name</label>
                        <input
                          type="text"
                          className="input"
                          placeholder="First Name"
                          maxLength={30}
                          {...register("firstName", {
                            required: "First Name is required",
                            maxLength: {
                              value: 30,
                              message: "First Name cannot exceed 30 characters",
                            },
                          })}
                        />
                        {errors.firstName && (
                          <p className="text-danger err-message">
                            {errors.firstName.message}
                          </p>
                        )}
                      </div>
                      <div className="last-name-input mt-1">
                        <label>Last Name</label>
                        <input
                          type="text"
                          className="input"
                          placeholder="Last Name"
                          maxLength={30}
                          {...register("lastName", {
                            required: "Last Name is required",
                            maxLength: {
                              value: 30,
                              message: "Last Name cannot exceed 30 characters",
                            },
                          })}
                        />
                        {errors.lastName && (
                          <p className="text-danger err-message">
                            {errors.lastName.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="gender-dob mt-2">
                      <div className="select-gender mt-1">
                        <label>Gender</label>
                        <select
                          className="input"
                          {...register("gender", {
                            required: "Gender is required",
                          })}
                        >
                          <option value="" disabled selected>
                            Gender
                          </option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                        {errors.gender && (
                          <p className="text-danger err-message">
                            {errors.gender.message}
                          </p>
                        )}
                      </div>
                      <div className="dob-input mt-1">
                        <label>Date Of Birth</label>
                        <input
                          type="date"
                          className="input"
                          {...register("dob", {
                            required: "Date of Birth is required",
                            validate: {
                              futureDate: (value) =>
                                new Date(value) <= new Date() ||
                                "DOB cannot be in the future",
                              minAge: (value) => {
                                const today = new Date();
                                const dob = new Date(value);
                                const age =
                                  today.getFullYear() - dob.getFullYear();
                                const monthDifference =
                                  today.getMonth() - dob.getMonth();
                                const isBeforeBirthday =
                                  monthDifference < 0 ||
                                  (monthDifference === 0 &&
                                    today.getDate() < dob.getDate());

                                return (
                                  age - (isBeforeBirthday ? 1 : 0) >= 15 ||
                                  "You must be at least 15 years old"
                                );
                              },
                            },
                          })}
                        />
                        {errors.dob && (
                          <p className="text-danger err-message">
                            {errors.dob.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="phone-no mt-1">
                      <label>Phone Number</label>
                      <PhoneInput
                        country={"us"} // Default country
                        onChange={(phone, data) => {
                          setPhoneNo(phone);
                          setValue("phone", phone); // Set the phone number
                          setValue("countryCode", data.dialCode); // Set the country code
                          setValue("isoCode", data.countryCode); // Set the ISO country code

                          // Trigger validation for the phone field
                          trigger("phone");
                        }}
                        inputClass="input"
                        inputStyle={{
                          width: "100%",
                          height:"45px",
                          padding:"9px 50px",
                        }}
                      />
                      <input
                        type="hidden"
                        {...register("phone", {
                          required: "Phone Number is required",
                          minLength: {
                            value: 10,
                            message:
                              "Phone Number must be at least 10 characters",
                          },
                          maxLength: {
                            value: 15,
                            message: "Phone Number cannot exceed 15 characters",
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Phone Number must be numeric",
                          },
                        })}
                      />
                      {errors.phone && (
                        <p className="text-danger err-message">
                          {errors.phone.message}
                        </p>
                      )}
                    </div>
                    <div className="mt-1">
                      <label className="">Email</label>
                      <input
                        type="email"
                        className="input"
                        placeholder="Email"
                        maxLength={50}
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^\S+@\S+$/i,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <p className="text-danger err-message">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="text-center">
                    <button type="submit" className="btn SignUp_Button" disabled={loading} >
                      {loading ? "Signing Up..." : "Sign Up"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </Main>
  );
};

export default SignUp;
