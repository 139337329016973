import React from 'react'
import { FooterSectionStyle } from './style'
import mainLogo from "../../images/bannersectionImages/mainLogo.png";
import appstoreBlue from "../../images/bannersectionImages/appstoreblue.png";
import googlepalyBlue from "../../images/bannersectionImages/googlebtnblue.png";
import goToTop from "../../images/go_top.png"

const FooterSection = () => {

  return (
    <FooterSectionStyle>
        {/* Footer-Section start */}
        <footer>
      <div className="top_footer" id="contact">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
           
            <div className="col-lg-4 col-md-6 col-12">
              <div className="abt_side">
                <div className="logo">
         
                  <img src={mainLogo} alt="image" />
                </div>
                <ul>
                  <li>
                    <a href="#">support@example.com</a>
                  </li>
                  <li>
                    <a href="#">+1-900-123 4567</a>
                  </li>
                </ul>
                <ul className="social_media">
                  <li>
                    <a href="#">
                      <i className="icofont-facebook" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icofont-twitter" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icofont-instagram" />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icofont-pinterest" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/* footer link 2 */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="links">
                <h3>Explore</h3>
                <ul>
                  <li>
                    <a id='home' href="#home">Home</a>
                  </li>
                  <li id='features'>
                    <a  href="#features">Features</a>
                  </li>
                  <li>
                    <a id='aboutus' href="#aboutus">Dashboard Overview</a>
                  </li>
                  <li>
                    <a id='modernUI'  href="#modernUI">Product Detail</a>
                  </li>
                </ul>
              </div>
            </div>
            {/* footer link 3 */}
            <div className="col-lg-3 col-md-6 col-12">
              <div className="links">
                <h3>Help &amp; Suport</h3>
                <ul>
                  {/* <li>
                    <a id='faqs' href="#faqs">FAQs</a>
                  </li> */}
                  <li>
                    <a id='how_it_work' href="#how_it_work">How it works</a>
                  </li>
                  {/* <li>
                    <a href="/term">Terms &amp; conditions</a>
                  </li>
                  <li>
                    <a href="/policy">Privacy policy</a>
                  </li> */}
                </ul>
              </div>
            </div>
            {/* footer link 4 */}
            <div className="col-lg-2 col-md-6 col-12">
              <div className="try_out">
                <h3>Let’s Try Out</h3>
                <ul className="app_btn">
                  <li>
                    <a href="#">
                      <img src={appstoreBlue} alt="image" className='appstore-btn' />
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={googlepalyBlue} alt="image" className='appstore-btn' />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </div>
      {/* last footer */}
      <div className="bottom_footer">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="ro">
            <div className="text-center">
              <p>© Copyrights 2024. All rights reserved.</p>
            </div>
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </div>
      {/* go top button */}
     
    </footer>
    {/* Footer-Section end */}
    
    </FooterSectionStyle>
  )
}

export default FooterSection
