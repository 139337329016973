import styled from "styled-components";
export const FaqSectionStyle = styled.div`
  /* -------------FAQ-Section-Css-Start----------------- */

  /* faq wraper */
  .faq_section .faq_panel {
    margin-top: 40px;
  }

  /* faq box */
  .faq_section .faq_panel .card {
    border: none;
    margin-bottom: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 10px #ede9fe;
    padding: 16px 0;
  }

  .faq_section .faq_panel .card:last-child {
    margin-bottom: 0;
  }

  .faq_section .faq_panel .card-header {
    background-color: transparent;
    border: none;
    padding-bottom: 0;
    padding-top: 0;
  }

  .faq_section .faq_panel .card-header .btn {
    padding: 0;
    color: var(--dark-purple);
    font-weight: 600;
    font-size: 20px;
  }

  .faq_section .faq_panel .card-header .btn.active {
    color: var(--purple);
  }

  .faq_panel .accordion button,
  .faq_panel .accordion button:hover,
  .faq_panel .accordion button:focus {
    text-decoration: none;
  }

  .faq_section .faq_panel .card-header .icon_faq {
    position: absolute;
    right: 20px;
    color: #839bc0;
  }

  /* faq heading h2 */
  .faq_section .faq_panel .card-header h2 {
    line-height: 1;
  }

  /* faq paragraph */
  .faq_section .faq_panel .card-body {
    padding-bottom: 0;
  }
  .text-clr{
    color: var(--body-text-purple);
    
  }

  /* ------Media-Query-Start--------- */

  @media screen and (max-width: 1200px) {
    .row_am {
      padding: 50px 0;
    }
  }

  @media screen and (max-width: 767px) {
    body {
      font-size: 14px;
      text-align: center;
    }
    .row_am {
      padding: 30px 0;
    }
    .section_title p br {
      display: none;
    }
    .free_app_section .container .free_app_inner .free_text .section_title h2,
    .section_title h2 {
      font-size: 24px;
    }

    /* faq section */
    .faq_section .faq_panel .card-header h2 {
      text-align: left;
    }
    .faq_section .faq_panel .card-header .btn {
      font-size: 16px;
      padding-right: 30px;
    }
    .faq_section .faq_panel .card-body p {
      text-align: left;
    }
    .text-clr{
    font-size: 14px;
    }
  }
  @media screen and (min-width:1200px) {
    .container {
        max-width: 1170px;
    }
}
`;
