import React from "react";
import img from "../../images/Frame.svg";
import { useNavigate } from "react-router-dom";
const BlankPage = () => {
  const navigate = useNavigate();
  const gotoHome = () =>{
    navigate('/')
  }
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <div style={{ marginRight: "70px" }}>
        <img src={img} alt="" />
      </div>
      <h1
        style={{
          fontSize: "24px",
          fontWeight: "600",
          marginTop: "20px",
        }}
      >
      Your request has been sent to the admin, and it is currently under review. Once it is accepted, you will receive an email notification.
      </h1>
    
      <div className="text-center">
                    <button className="btn SignUp_Button" style={{
                      
                        backgroundColor:" #0c3a2d",
                        color: "white",
                        width: "100%",
                        border:" 6px",
                        height:" 45px",
                        marginTop: "14px",
                    }}
                    onClick={gotoHome}>
                      Home
                    </button>
                  </div>
    
    </div>
  );
};
export default BlankPage;