import styled from "styled-components";
export const AboutSectionStyle = styled.div`
  /* -----------------About-App-Section-Css-Start------------------ */

  .ui_images {
    display: flex;
    justify-content: center;
    position: relative;
    align-items: center;
  }
  .about_app_section.row {
    align-items: center;
  }

  .about_app_section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left_img img {
    width: 90%;

    margin-top: 50px;
  }

  /* about us section wraper */
  .about_app_section .ui_images {
    display: flex;
    align-items: center;
    position: relative;
  }

  /* about us section images*/
  .about_app_section .ui_images .left_img {
    max-width: 100%;
    display: flex;
    justify-content: center;
  }

  .about_app_section .about_img .frame_img img {
    max-width: 100%;
  }

  .about_app_section .about_text .section_title {
    text-align: left;
  }

  .about_app_section .about_text .section_title h2 {
    margin-bottom: 15px;
  }

  .about_app_section .about_text .list {
    margin-top: 45px;
    margin-bottom: 45px;
  }

  .about_app_section .about_text .list ul {
    position: relative;
  }

  .about_app_section .about_text .list ul li {
    font-size: 17px;
    color: var(--dark-purple);
    font-weight: normal;
    margin-bottom: 8px;
  }

  /* ------Media-Query-Start--------- */

  @media screen and (max-width: 1200px) {
    .row_am {
      padding: 50px 0;
    }
    /* about app section */
    .about_app_section .about_text .app_statstic li {
      width: 210px;
      padding-left: 20px;
    }
    .about_app_section .about_text .app_statstic li p:first-child {
      font-size: 30px;
    }
  }

  @media screen and (max-width: 992px) {
    /* about app section */
    .about_app_section .row {
      flex-direction: column-reverse;
    }
    .about_app_section .ui_images .left_img {
      justify-content: center;
      margin-top: 40px;
      margin-left: 0;
    }
    .left_img img {
      width: 90%;

      margin-top: 0px;
    }

    .about_app_section .about_text .app_statstic {
      justify-content: flex-start;
    }
    .about_app_section .about_text .app_statstic li {
      width: 35%;
      margin-right: 30px;
    }
    .about_app_section .about_img::before {
      left: 35%;
    }
  }

  @media screen and (max-width: 767px) {
    body {
      font-size: 14px;
      text-align: center;
    }
    .row_am {
      padding: 30px 0;
    }
    .section_title p br {
      display: none;
    }
    .free_app_section .container .free_app_inner .free_text .section_title h2,
    .section_title h2 {
      font-size: 24px;
    }

    /* about app section */
    .about_app_section .about_text,
    .about_app_section .about_text .section_title {
      text-align: center;
    }
    .about_app_section .about_text .app_statstic {
      margin-top: 0;
      justify-content: space-between;
    }
    .about_app_section .about_text .app_statstic li {
      width: 48%;
      margin-right: 0;
    }
    .about_app_section .about_text .app_statstic li p:first-child {
      font-size: 18px;
    }
    .about_app_section .about_img {
      margin: 0;
      margin-top: -10px;
      justify-content: center;
    }
    .about_app_section .about_img::before {
      width: 350px;
      height: 350px;
    }
  }

  @media screen and (max-width: 479px) {
    /* about section */
    .about_app_section .about_img::before {
      left: 49%;
    }
  }
  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1170px;
    }
  }
`;
