import styled from "styled-components";
import bghero from "../../images/herobg.png";
export const ProductDetailStyle = styled.div`
  .section-main {
    background-image: url(${bghero});
    height: 100%;
  }


  .navLogo {
    padding-left: 0;
    padding-right: 0;
  }

  .navLogo {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-brand img {
    width: 200px;
  }

  /* product section */

  .product_section .container{
    max-width: 1370px;
}
.product_inner {
    background-color: var(--bg-white);
    padding: 40px 0;
    border-radius: 30px;
    box-shadow: 0px 4px 30px #73a59742;
}

/* features box block */
.product_section .product_inner {
    max-width: 1170px;
    margin: 0 auto;
    padding: 10px;
}

/* features section box */

.product_section .product_inner {
    width: 100%;
    background-color: var(--light-purple);
    border-radius: 12px;
   
}








.name {
   
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.41px;
    color: var(--body-text-purple);

    /* padding-bottom: 28px; */
  }
  .prdct-img-text {
    font-weight: 500;
    size: 16px;
    letter-spacing: 0.2px;
    color: var(--body-text-purple);

  }
  .details {
  
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 0.2px;
    color: var(--body-text-purple);

  }
  .main-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 10px 0px 10px 0px; */
  }
  .published-flex {
    display: flex;
    align-items: center;
    gap: 9px;
  }
  .ios-button {
    display: flex;
    align-items: center;
    /* padding-inline: 15px; */
  }
  .icon-width {
    width: 38px;
    height: 38px;
  }
  .published {
  
    font-weight: 600;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: var(--body-text-purple);

  }
  /* .padding-text {
    padding: 15px 0px 0px 0px;
  } */

.card{
    background-color: var(--light-purple);
}
/* .div1 td{
    background-color: var(--light-purple);
}
.div2 td{
    background-color: var(--light-purple);
}
.div3 td{
    background-color: var(--light-purple);
}
.div4 td{
    background-color: var(--light-purple);
} */
  .image-size {
    width: 73px;
    height: 73px;
    border-radius: 20px;
  }
  .card-main-flex {
    display: flex;
    gap: 30px;
  }
  @media (max-width: 1200px) {
    .card-main-flex {
      flex-wrap: wrap;
    }
  }
  .categories-flex {
    display: flex;
    gap: 100px;
   
  }
  .categories-flex-2 {
    display: flex;
    gap: 70px;
  }

  .star-width {
    width: 16px;
    height: 14px;
  }

  .table-headings{
    color: var(--body-text-purple);
  }
  .table-heading {
    color: var(--body-text-purple);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2px;
    
    width: 50%;
  }
  @media (max-width: 425px) {
    .table-heading {
      white-space: nowrap;
    }
  }
  .table-data {
   
    font-weight: 600;
    font-size: 16px;
    letter-spacing: -0.41px;
    color: var(--body-text-purple);
    width: 50%;
  }
  @media (max-width: 1038px) {
    .table-data {
      width: 100%;
    }
  }

  .color {
    border-radius: 15px;
    border: 2px solid #e2e8f0;
    cursor: pointer;
  }
  .description {
  
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.25px;
    color: var(--body-text-purple);
    
  }
  @media (max-width: 1038px) {
    .description {
      text-align: justify;
    }
  }
  .seeAll {

    font-weight: 400;
    font-size: 14px;
    color: var(--body-text-purple);
    cursor: pointer;
  }
  .comment-pic {
    width: 45px;
    height: 45px;
  }
  .review-flex {
    display: flex;
    align-items: center;
    /* padding: 0px 10px; */
  }
  .client-name {

    font-weight: 600;
    font-size: 16px;
    color: var(--body-text-purple);
  }
  .report {

    font-weight: 600;
    font-size: 14px;
    color: var(--body-text-purple);
  }
  .comment-rating {

    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2px;
    color: var(--body-text-purple);
  }
  .comment-img-flex {
    display: flex;
    gap: 10px;
    /* padding-top: 10px; */
  }
  .date {

    font-weight: 400;
    font-size: 14px;
    color: var(--body-text-purple);  }
  .comment {

    font-weight: 400;
    font-size: 16px;
    color: var(--body-text-purple);
  }
  .table-responsive {
    width: 100%;
  }
  @media (max-width: 1100px) {
    .table-responsive {
      overflow-x: auto; /* Enable horizontal scrolling */
      width: 100%;
    }
  }
  .table-width {
    width: 100%;
  }
  @media (max-width: 1038px) {
    .table-width {
      width: 1150px;
    }
  }

  .parent {
    background-color: white;
    border-radius: 15px;
    padding: 10px;
    width: 100%;
    
  }
  .div1 {
    width: 100%;
    display: flex;
    gap: 20px;
    
  }
  @media (max-width: 1038px) {
    .div1 {
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  .div2 {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  @media (max-width: 1038px) {
    .div2 {
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  .div3 {
    width: 100%;
    display: flex;
    gap: 20px;
  }
  @media (max-width: 1038px) {
    .div3 {
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  .div4 {
    width: 100%;
    display: flex;
    gap: 20px;


  }
  @media (max-width: 1038px) {
    .div4 {
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  .sub-parent {
    display: flex;
    gap: 30px;
    align-items: center;
    
  }
  .dimention {
    display: flex;
    width: 100%;
  }

  @media (max-width: 1038px) {
    .dimention {
      flex-wrap: wrap;
      gap: 13px;
      padding: 9px 9px;
    }
  }
  @media (max-width: 1280px) {
    .dimention {
      gap: 82px;
    }
  }
  @media (max-width: 1038px) {
    .dimention {
      gap: 20px;
    }
  }
  .dimention-flex {
    display: flex;
    width: 100%;
    gap: 27px;
  }
  @media (max-width: 1280px) {
    .dimention-flex {
      gap: 24px;
    }
  }
  .dimnetion-parent {
    display: flex;
    width: 100%;
    gap: 12em;
  }
  @media (max-width: 1038px) {
    .dimnetion-parent {
      flex-wrap: wrap;
      gap: 0px;
    }
  }
  @media (max-width: 1280px) {
    .dimnetion-parent {
      gap: 2em;
    }
  }
  .table-headings {
    width: 168px;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.2px;
    
  }
  .product-img {
    width: 133px;
    height: 136px;
    margin-top: 15px;
    border-radius: 10px;
    /* background-color: #f9f9f9; */
  }
  @media (max-width: 1038px) {
    .product-img {
      width: 100%;
    }
  }

  .parent-flex {
    width: 100%;
    display: flex;
    gap: 44px;
    margin-top: 15px;
  }
  @media (max-width: 2200px) {
    .parent-flex {
      flex-wrap: wrap;
      gap: 12px;
    }
  }
  .product-img-flex {
    display: flex;
    gap: 10px;
  }
  .product-flex {
    display: flex;
    gap: 44px;
    margin-top: 14px;
  }
  @media (max-width: 1038px) {
    .product-flex {
      flex-wrap: wrap;
      gap: 10px;
    }
  }

  .download_section {
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
}

/* banner button */
.download_section .app_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    @media screen and (max-width:400px) {
flex-direction: column;
align-content: center;
gap: 20px;
    }
}
.download_section .app_btn li .app_store{
    display: block;
    background-color: var(--bg-white);
    border: 2px solid #0C3A2D;
    position: relative;
    border-radius: 12px;
    transition: .4s all;
}
.download_section .app_btn li a .blue_img {
    width: 180px !important;
    height: 60px !important;
    cursor: pointer;
  
}

.download_section .app_btn li a .white_img {
    width: 180px !important;
    height: 60px !important;
    cursor: pointer;
}

`;
