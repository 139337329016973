import React from "react";
import { BeautifullUiDesignStyle } from "./style";

import modernOne from "../../images/modernUI/modernOne.png";


const BeautifullUiDesign = () => {
  return (
    <BeautifullUiDesignStyle>
      {/* ModernUI-Section-Start */}
      <section className="row_am modern_ui_section" id="modernUI">
        {/* container start */}
        <div className="container">
          {/* row start */}
          <div className="row">
            <div className="col-lg-6">
              {/* UI content */}
              <div className="ui_text">
                <div
                  className="section_title"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                  data-aos-delay={100}
                >
                  <h2>
                  <span>Product </span>Detail
                  </h2>
                  <p>
                  The product detail page of the "LotusPro" furniture app is designed to provide users with comprehensive information about individual furniture items, helping them make informed purchase decisions. This page prominently displays high-quality images of the product from various angles, often arranged in a carousel format that allows users to view multiple pictures.
                  </p>
                </div>
                <ul className="design_block">
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <h4>Product Overview</h4>
                    <p>
                      Join exciting sports events with ease. Athlink allows you
                      to find and participate in a variety of competitions.
                    </p>
                    <p>
                    Shows important details such as category collection, material, product SKU or Code, dimension, and available colors.
                    </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <h4>Product Pricing and Description</h4>
                    <p>
                    Shows both the sale price and the standard price.
                    </p>
                    <p>
                    Displays a brief product description, allowing the vendor to provide more information about the furniture.
                    </p>
                  </li>
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <h4>Product Reviews</h4>
                    <p>
                    Lists customer reviews, along with options to report a review if necessary.
                    </p>
                    <p>
                    Each review includes the reviewer's name, date, rating, and written feedback.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6">
              {/* UI Image */}
              <div
                className="ui_images"
                data-aos="fade-in"
                data-aos-duration={1500}
              >
                <div className="left_img">
                  <img
                    className="moving_position_animatin "
                    src={modernOne}
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* row end */}
        </div>
        {/* container end */}
      </section>
      {/* ModernUI-Section-end */}
    </BeautifullUiDesignStyle>
  );
};

export default BeautifullUiDesign;
