import React from 'react'
import { FeatureStyle } from './style'

import featureOne from "../../images/featureSection/featureOne.png";
import featureTwo from "../../images/featureSection/featureTwo.png";
import featureThree from "../../images/featureSection/featureThree.png";


const FeaturesSection = () => {
  return (
    <FeatureStyle>
        {/* Features-Section-Start */}
        <section className="row_am features_section" id="features">
      {/* container start */}
      <div className="container">
        <div className="features_inner">
          <div
            className="section_title"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-delay={300}
          >
            {/* h2 */}
            <h2>
              <span>Features</span> that makes app different!
            </h2>
            {/* p */}
            <p>
            LotusPro sets itself apart with features designed to enhance the furniture shopping experience. The app offers personalized recommendations based on your style preferences, helping you find the perfect pieces for your space. With an intuitive design board feature, you can easily create and visualize your room layouts, experimenting with different styles and arrangements. LotusPro also connects you with a community of design enthusiasts, allowing you to share ideas, get feedback, and draw inspiration from others. Whether you're furnishing a new home or refreshing your current space, LotusPro makes the process enjoyable and tailored to your unique taste.
            </p>
          </div>
          {/* story */}
          <div className="features_block">
            <div className="row">
              <div className="col-md-4">
                <div
                  className="feature_box"
                  data-aos="fade-up"
                  data-aos-duration={1500}
                >
                  <div className="image">
                    <img src={featureOne} alt="image" />
                  </div>
                  <div className="text">
                    <h4 className='text-color'>Explore Product</h4>
                    <p>
                    LotusPro helps users easily browse and filter furniture by category, price, or style. You can compare products, view ratings, and save favorites. The app makes furniture shopping simple and personalized, helping you find the perfect pieces for your home.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="feature_box"
                  data-aos="fade-up"
                  data-aos-duration={1700}
                >
                  <div className="image">
                    <img src={featureTwo} alt="image" />
                  </div>
                  <div className="text">
                    <h4>Product Detail</h4>
                    <p>
                    LotusPro lets customers easily view detailed information about each furniture item. The app provides clear descriptions, high-quality images, and pricing. Users can quickly navigate through categories and read reviews to make informed decisions.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div
                  className="feature_box"
                  data-aos="fade-up"
                  data-aos-duration={1900}
                >
                  <div className="image">
                    <img src={featureThree} alt="image" />
                  </div>
                  <div className="text">
                    <h4>Cart</h4>
                    <p>
                    With LotusPro, you can easily add items to your cart as you shop. When you’re ready, go to your cart to review your selections. The checkout process is simple and secure, allowing you to enter your payment details and complete your purchase quickly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* container end */}
    </section>
    {/* Features-Section-end */}
    
    </FeatureStyle>
  )
}

export default FeaturesSection
