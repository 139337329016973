import styled from "styled-components";
export const FeatureStyle = styled.div`

/* ----------Feature-Detail-Section-start------ */

/* features section wraper */

.features_section .container {
    max-width: 1370px;
}
.section_title p{
    
    padding:0px 50px ;
    text-align: center;
}

.features_section .features_inner {
    background-color: var(--bg-white);
    padding: 70px 0;
    border-radius: 30px;
    box-shadow: 0px 4px 30px #73a59742;
}

/* features box block */
.features_section .features_inner .features_block {
    max-width: 1170px;
    margin: 0 auto;
    padding: 10px;
}

/* features section box */

.features_section .features_inner .feature_box {
    width: 100%;
    background-color: var(--light-purple);
    border-radius: 12px;
    text-align: left;
}

.features_section .features_inner .feature_box .image {
    position: relative;
    max-width: 100%;
}

.features_section .features_inner .feature_box .image img {
    border-radius: 12px 12px 0 0;
    max-width: 100%;
}

.features_section .features_inner .feature_box .text {
    padding: 30px;
}

.features_section .features_inner .feature_box .text h4 {
    font-size: 20px;
    color: var(--dark-purple);
    font-weight: 600;
}


/* ------Media-Query-Start--------- */
@media screen and (max-width:1300px) {

/* features section */
.features_section .feature_detail .left_data {padding-left: 75px;}
.features_section .feature_detail .right_data {padding-right: 75px;}
}


@media screen and (max-width:1200px) {
.row_am {padding: 50px 0;}
/* features section */
.features_section .feature_detail .feature_box {width: 300px;}
.features_section .feature_detail .left_data {padding-left: 15px;}
.features_section .feature_detail .right_data {padding-right: 15px;}
}

@media screen and (max-width:992px) {

/* features section */
.features_section .feature_detail .feature_img {width: 275px;}
.features_section .feature_detail .feature_box .data_block {margin-bottom: 30px;}
.features_section .feature_detail .feature_box {width: 200px;}
}

@media screen and (max-width:767px) {

body {font-size: 14px; text-align: center;}
.row_am {padding: 30px 0;}
.section_title p br {display: none;}
.free_app_section .container .free_app_inner .free_text .section_title h2, .section_title h2 {font-size: 24px;}
/* features section */
.features_section .feature_detail {flex-direction: column-reverse; padding-top: 30px; margin-top: 0;}
.features_section .feature_detail .feature_img {position: relative; top: auto; left: auto; transform: none; width: 75%; margin: 0 auto;}
.features_section .feature_detail .feature_box {width: 100%; padding: 0 15px; text-align: center;}
.features_section .features_inner .feature_box {margin-bottom: 30px;}
.features_section .features_inner {padding: 25px 10px 0 10px;}
.features_section .feature_detail .left_data .data_block .icon {margin-right: 0;}
.features_section .feature_detail .right_data .data_block .icon {margin-left: 0;}
.features_section .feature_detail .feature_box .data_block {margin-bottom: 0; margin-top: 30px;}


}

@media screen and (max-width:640px) {
.features_section .features_inner .feature_box .image img {width: 100%;}
}

@media screen and (min-width:1200px) {
    .container {
        max-width: 1170px;
    }
}






`;