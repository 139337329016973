
import axios from "../config/axios.config";

export const postRequest = async (url, data, params = {}) => {
  try {
    const response = await axios.post(url, data, params);
    console.log("RESPONSE:", response);
    return response.data;
  } catch (error) {
    return errorHandler(error);
  }
};

export const getRequest = async (url, params = {}) => {
  console.log(url);
  console.log(process.env.REACT_APP_BASE_URL)
  try {
    const response = await axios.get(url, { params: { ...params } });
    return response.data;
  } catch (error) {
    console.log(error);
    // return errorHandler(error);
  }
};

export const errorHandler = (error) => {
  let message = "An unknown error occurred.";
  if (error.response) {
    const res = error.response.data;

    if (error.response.status === 401) {
      alert("Session expired. Please log in again.");
   
    } else {
      // alert("Something went wrong. Please try again later.");
     
    }
    if (res) {
      message = res.message || res.metadata?.message || message;
    } else {
      message = JSON.stringify(res);
    }
  } else if (error?.message) {
    message = error.message;
    alert(message);
  }
  return { error: message };
};