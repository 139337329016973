import React from "react";
import mainLogo from "../../images/bannersectionImages/mainLogo.png";
import { HeaderStyle } from "./style";
const HeaderSection = () => {
  return (
    <HeaderStyle>
      <header>
        <div className="container">
          {/* <nav className="navba">
          <div className="navLogo">
          <a className="navbar-brand" href="#">
            <img src={mainLogog} alt="image" className=""/>
          </a>
          </div>
         
        </nav> */}

          <nav className="navbar navbar-expand-lg">
            <div className="container-fluid ">
              <div className="navLogo">
                <a className="navbar-brand" href="#">
                  <img src={mainLogo} />
                </a>
              </div>

              {/* <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </button> */}
              <div
                // className="collapse navbar-collapse"
                // id="navbarSupportedContent"
              >
                <ul className="navbar-nv ms-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <a className="nav-link active text-light" aria-current="page" href="/signup">
                      Register as a Vendor
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
    </HeaderStyle>
  );
};

export default HeaderSection;
