import React from "react";
import { PrivacyPolicyStyle } from "./style";
import { useNavigate } from "react-router-dom";

import arrowLeft from "../../images/bannersectionImages/arrowLeft.png";
const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const backTo = () =>{
navigate("/")
  }
  return (
    <PrivacyPolicyStyle>
      <div className="d-flex p-4" style={{cursor:"pointer"}}>
        <div className="" onClick={backTo}>
          <img src={arrowLeft} />
        </div>
        <div className="text-center m-auto">
          <h3>Privacy Policy</h3>
        </div>
      </div>
      <div className="p-4">
        <h4>1. Information We Collect</h4>
        <ul className="listStyle">
          <li>
            <span className="subheading">Personal Information: </span>
            When you create an account, we collect personal information such as
            your name, email address, phone number, and profile details.
          </li>
          <li>
            <span className="subheading">Location Data: </span>
            With your consent, we collect precise geolocation data from your
            mobile device to enhance your experience.
          </li>
          <li>
            <span className="subheading">Usage Data: </span>
            We collect information about your interactions with our app,
            including browsing history, search queries, and engagement with
            content.
          </li>
          <li>
            <span className="subheading">Social Media Information: </span>
            If you log in through social media accounts, we may access your
            profile information from those platforms.
          </li>
        </ul>
      </div>
      <div className="px-4 py-2">
        <h4>2. How We Use Your Information</h4>
        <ul className="listStyle">
          <li>
            <span className="subheading">Personalization: </span>
            To personalize your experience and provide tailored content and
            recommendations.
          </li>
          <li>
            <span className="subheading">Analytics: </span>
            To perform analytics to improve our services, understand user
            behavior, and develop new features.
          </li>
          <li>
            <span className="subheading">Location-Based Services: </span>
            specific features and advertisements, if
            you have opted in.
          </li>
        </ul>
      </div>
      <div className="px-4 py-2">
        <h4>3. Sharing Your Information</h4>
        <ul className="listStyle">
          <li>
            <span className="subheading">Service Providers: </span>
            We share your data with third-party service providers who assist us in operating our app, conducting our business, or providing services to you.
          </li>
          <li>
            <span className="subheading">Legal Requirements: </span>
            We may disclose your information to comply with legal obligations or protect our rights.
          </li>
          <li>
            <span className="subheading">Affiliates and Partners: </span>
            We may share information with our affiliates and partners to improve our services and ensure user safety.
          </li>
        </ul>
      </div>
      <div className="px-4 py-2">
        <h4>4. Data Retention</h4>
        <ul className="listStyle">
          <li>
            <span className="subheading">Active Use: </span>
            We retain your personal information for as long as your account is active.
          </li>
          <li>
            <span className="subheading">  Account Closure: </span>
           After you close your account, we keep your data for a three-month period to investigate any potential misconduct. Thereafter, your data is deleted, except for information required to comply with legal obligations or resolve disputes.
          </li>
        </ul>
      </div>
      <div className="px-4 py-2">
        <h4>5. Cookies and Tracking Technologies</h4>
        <ul className="listStyle">
          <li>
            <span className="subheading">Cookies: </span>
         We use cookies to remember your preferences, enhance site functionality, and track usage patterns.
          </li>
          <li>
            <span className="subheading">Web Beacons and Pixel Tags: </span>
            Web Beacons and Pixel Tags: These technologies help us understand user interactions with our emails and advertisements.
          </li>
        </ul>
      </div>
      <div className="px-4 py-2">
        <h4>6. Your Rights</h4>
        <ul className="listStyle">
          <li>
            <span className="subheading">Access and Update: </span>
           You can access and update your personal information through your account settings.
          </li>
          <li>
            <span className="subheading">Delete Your Data: </span>
            You can delete your account, which will remove your profile from our service.
          </li>
          <li>
            <span className="subheading">Withdraw Consent: </span>
            You can withdraw your consent for data processing at any time by adjusting your settings or contacting us.
          </li>
        </ul>
      </div>
      <div className="px-4 py-2">
        <h4>7.Security</h4>
        <p>We implement industry-standard security measures to protect your data. However, no system is completely secure, and we cannot guarantee the absolute security of your information.</p>
      </div>
      <div className="px-4 py-2">
        <h4>Contact Us</h4>
        <p>If you have any questions or concerns about this Privacy Policy, please contact us at</p>
      </div>
    </PrivacyPolicyStyle>
  );
};

export default PrivacyPolicy;
