import styled from "styled-components";
export const HeaderStyle = styled.div`
header {
    position: absolute;
    width: 100%;
    z-index: 99999;
    transition: .4s all;
}

.navLogo {
    padding-left: 0;
    padding-right: 0;
    /* padding-top: 20px; */
}

.navbar-expand-lg .navbar-nav {
    
}

.navLogo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.navbar-brand img {
    width: 200px;
}
.navbar-nav .nav-item a{
    font-weight: 500;
    color: var(--text-white)
}
.navbar-toggler .navbar-toggler-icon{
    color: white !important;
    background-color: white;
    border: white;
}







@media screen and (max-width:1200px) {

.row_am {padding: 50px 0;}

/* navigation bar section */
.navbar-expand-lg .navbar-nav {padding: 5px 8px; color: var(--text-white);}
.navbar-expand-lg .navbar-nav {padding: 9px 30px;}
.navbar-expand-lg .navbar-nav{right: 5px;}

}

@media screen and (max-width:992px) {

/* navigation section */
.navbar-expand-lg .navbar-nav {align-items: flex-start;}
.navbar-expand-lg .navbar-nav{padding: 20px 15px; margin-bottom: 30px; background-color: var(--bg-white); border-radius: 15px; }
.navbar-expand-lg .navbar-nav .nav-item a{ color:black}

}

@media screen and (max-width:767px) {

body {font-size: 14px; text-align: center;}
.row_am {padding: 30px 0;}
.section_title p br {display: none;}
.free_app_section .container .free_app_inner .free_text .section_title h2, .section_title h2 {font-size: 24px;}
/* navbar section */
.navbar {padding-top: 15px;}
.navbar-expand-lg .navbar-nav .has_dropdown .sub_menu ul li a {text-align: left; line-height: 1.5; font-size: 14px;}

}


@media screen and (min-width:1200px) {
    .container {
        max-width: 1170px;
    }
}

`;