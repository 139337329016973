import React from 'react'
import { AboutSectionStyle } from './style'

import aboutFrameImg from "../../images/AboutUS/aboutUSOne.png"




const AboutSection = () => {
  return (
   <AboutSectionStyle>
     {/* About-App-Section-Start */}
     <section className="row_am about_app_section" id='aboutus'>
      {/* container start */}
      <div className="container">
        {/* row start */}
        <div className="row">
          <div className="col-lg-6">
          
            <div
                className="ui_images"
                data-aos="fade-in"
                data-aos-duration={1500}
              >
                <div className="left_img">
                  <img
                    className="moving_position_animatin "
                    src={aboutFrameImg}
                    alt="image"
                  />
                </div>
              </div>
          </div>
          <div className="col-lg-6">
            {/* about text */}
            <div className="about_text">
              <div
                className="section_title"
                data-aos="fade-up"
                data-aos-duration={1500}
                data-aos-delay={100}
              >
                {/* h2 */}
                <h2>
                Dashboard <span>Overview</span>
                </h2>
                {/* p */}
                <p>
                The LotusPro dashboard provides a comprehensive overview of business operations, focusing on order management and customer interactions. The dashboard allows users to quickly assess the order flow, customer and product statistics, pending or processing orders, and other sales-related metrics. It’s a real-time summary interface for business operations related to sales, customer orders, and product management.
                </p>
                {/* UL */}
                <ul className="list">
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <i className="icofont-verification-check icons" /> <span className='fw-semibold'>Dashboard:</span>  The default home screen where users can view an overview of key metrics.
                  </li>
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <i className="icofont-verification-check tick-icon" /> <span className='fw-semibold'>Customers:</span>  For managing and viewing customer-related information.
                  </li>
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <i className="icofont-verification-check" /> <span className='fw-semibold'>Products:</span> To manage product inventory or catalog.
                  </li>
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <i className="icofont-verification-check" /> <span className='fw-semibold'>Orders: </span>   Focused on tracking, processing, and managing orders.
                  </li>
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <i className="icofont-verification-check" /> <span className='fw-semibold'>Disputes, Collections, Policies:</span> Additional navigation options depending on the specific needs of the application (e.g., handling disputes, managing policies).
                  </li>
                  <li data-aos="fade-up" data-aos-duration={1500}>
                    <i className="icofont-verification-check" /> <span className='fw-semibold'>Wallet/Financial Info:</span> For accessing financial data like earnings, transactions, or payouts.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* row end */}
      </div>
      {/* container end */}
    </section>
    {/* About-App-Section-end */}
   </AboutSectionStyle>
  )
}

export default AboutSection
