import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import FooterSection from "../../component/footerSection";
import HeaderSection from "../../component/headerSection";
import BannerSection from "../../component/bannerSection";
import FeaturesSection from "../../component/featuresSection";
import AboutSection from "../../component/aboutSection";
import WorkesSection from "../../component/workesSection";
import FaqSection from "../../component/faqSection";
import BeautifullInterfaceSection from "../../component/beautifullInterfaceSection";
import DownloadSection from "../../component/downloadSection";
import BeautifullUiDesign from "../../component/beautifulUiSection";
import Loader from "../../component/loader/index";
import AOS from "aos";
import gotoTop from "../../images/go_top.png";
import { MainPageStyle } from "./style";

const MainPage = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    AOS.init({
      duration: 1500,
      delay: 200,
      // once: false,
    });
  }, []);
  useEffect(() => {
    setLoading(true);
    const handleLoading = () => {
      setLoading(false);
      AOS.refresh();
    };

    const timeout = setTimeout(handleLoading, 800);

    return () => clearTimeout(timeout);
  }, [location]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <MainPageStyle>
      {loading && <Loader />}
      <div className="app">
        <span className="scroll-to-top" onClick={scrollToTop}>
          <img src={gotoTop} alt="image" />
        </span>
      </div>

      <div>
        <HeaderSection />
        <BannerSection />
        <FeaturesSection />
        <AboutSection />
        <BeautifullUiDesign />
        <WorkesSection />
        {/* <FaqSection /> */}
        <BeautifullInterfaceSection />
        <DownloadSection />
        <FooterSection />
      </div>
    </MainPageStyle>
  );
};

export default MainPage;
