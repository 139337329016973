import {
postRequest,getRequest
} from "../methods/index.js"

export const DataApi = {
    postData : async (params)=> postRequest("/api/users",params),
    getproductsdetails: async (id, body) => getRequest(`/api/products/${id}`, body),
    getproductsReviews: async (params) => getRequest("/api/reviews", params),
 }



