import styled from "styled-components"; 
import herobg from "../../images/herobg.png"
 export const BannerStyle = styled.div`
/* ---------Hero-banner-Css-Start------------------ */
/* hero banner  wraper */




.banner_section {
    padding-top: 200px;
    position: relative;
    background-image: url(${herobg});
}

.banner_section .container {
    position: relative;
}

.banner_section .banner_images {
    margin: 240px 0 0 0;
    max-width: 100%;
    padding: 0 50px;
}


.banner_section .banner_images .banner_screen {
    margin: 0 auto;
    max-width: 100%;
    padding: 0;
    margin:0; 
    text-align: center;
}

.banner_section .banner_images .banner_screen img {
    max-width: 100%;
    border-radius: 16px;
    border: #0C3A2D solid 1px;
}

.banner_section .banner_images .banner_screen.screen1 {
    margin-top: -100px;
}

.banner_section .banner_images .banner_screen.screen1 img{
    animation-delay:1s;
}

.banner_section .banner_images .banner_screen.screen2 {
    margin-top: -200px;
}

.banner_section .banner_images .banner_screen.screen2 img{
    animation-delay:3s;
}

.banner_section .banner_images .banner_screen.screen3 img {
    border: #0C3A2D solid 4px;
    animation-delay:1s;
}

.banner_section .banner_images .banner_screen.screen4 {
    margin-top: -200px;
}

.banner_section .banner_images .banner_screen.screen4 img {
    animation-delay:2s;
}

.banner_section .banner_images .banner_screen.screen5 {
    margin-top: -100px;
}

.banner_section .banner_images .banner_screen.screen5 img {
    border: #0C3A2D solid 4px;
    animation-delay:1s;
}


.owl-carousel .owl-item img {
    max-width: 100%;
    width: auto;
}


/* wave backgound after banner */
.banner_section::after {
    content: "";
    display: block;
    background:#eff5f7;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 350px;
    background-position: center;
    margin-top: -350px;
}

.banner_section .row {
    align-items: center;
}

/* hero banner text */
.banner_section .banner_text {
    margin-top: 0px;
    text-align: center;
}

/* hero banner heading h1 */
.banner_section .banner_text h1 {
    font-size: 65px;
    color: var(--text-white);
    letter-spacing: -1.5px;
    font-weight: 700;
}

.banner_section .banner_text h1 span {
    color: var(--purple);
}

.banner_section .banner_text p {
    color: var(--text-white);
    padding: 20px 50px;
}

/* banner video  */
.banner_section .yt_video {
    max-width: 350px;
    margin: 0 auto;
    position: relative;
}



/* hero banner images */
.banner_section .banner_slider {
    display: flex;
    position: relative;
}


/* hero banner shape animation */
.banner_section .banner_shape1, .banner_shape2, .banner_shape3 { 
    position: absolute; }

.banner_section .banner_shape1 {
    top: 200px;
    left: -15%;
    animation: mymove 10s infinite;
}

.banner_section .banner_shape2 {
    top: 100px;
    right: -10%;
    animation: mymove 5s infinite;
}

.banner_section .banner_shape3 {
    top: 400px;
    right: -20%;
    animation: mymove 3s infinite;
}
.banner_screen .moving_position_animatin{
    border: 4px solid black;
}
.border-cls{
border: 4px solid #0C3A2D;
}
.banner_section .banner_images .banner_screen img {
    border: #0C3A2D solid 4px;
    animation-delay: 1s;
}


@keyframes mymove {
  50% {transform: rotate(180deg);}
}


/* ------------Trusted-Section-Css-Start----------- */

/* trusted logos wraper */
.trusted_section {
    margin-top: 40px;
}

.trusted_section .company_logos {
    padding-top: 20px;
}

.trusted_section .company_logos img {
    filter: grayscale(1);
    margin: 0 auto;
    transition: .4s all;
}

.trusted_section .company_logos img:hover {
    filter: grayscale(0);
}


/* ------------download-Section-Css-Start----------- */

/* download wraper */
.download_section {
    margin: 0 auto;
    margin-top: 40px;
    text-align: center;
}

/* banner button */
.download_section .app_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    @media screen and (max-width:400px) {
flex-direction: column;
align-content: center;
gap: 20px;
    }
}

.white_img .blue_img{
    @media screen and (max-width:400px) {
        display: flex;
    align-items: center;
    align-content: center;
flex-direction: column;
gap: 20px;
    }
}

.download_section ul {

}

.download_section ul li{
    
}

/* banner button list */
.download_section .app_btn li .app_store{
    display: block;
    background-color: var(--bg-white);
    border: 2px solid #0C3A2D;
    position: relative;
    border-radius: 12px;
    transition: .4s all;
}
.download_section .app_btn li .app_store-qr{
    margin-left: 15px;
    display: block;
    /* background-color: var(--bg-white); */
    position: relative;
margin-top: 10px;
    transition: .4s all;
}

.download_section .app_btn li a.app_store {
    /* padding: 12px 40px; */
}

.download_section .app_btn li:last-child {
    /* margin-left: 25px; */
}

.download_section .app_btn li a img {
    transition: .4s all;
}

.download_section .app_btn li a .blue_img {
    width: 180px !important;
    height: 60px !important;
  
}

.download_section .app_btn li a .white_img {
    width: 180px !important;
    height: 60px !important;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
}

.app_store-qr{
    border: none;
}


/* .download_section .app-qr-code li a{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
} */

/* --------- Statastics Section-start------ */

/* statastics nomber */
.statistic_section .app_statstic {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 10px;
   
}

.statistic_section .app_statstic li {
    width: 100%;
    align-items: center;
}

.statistic_section .app_statstic li .icon {
    text-align: center;
}

.statistic_section .app_statstic li p {
    margin-bottom: 0;
    line-height: 1;
    color: var(--dark-purple);
    text-align: center;
    font-size: 20px;
}

.statistic_section .app_statstic li p:first-child {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    padding: 10px 0;
}

 
    @media screen and (max-width:1300px) {

/* banner section */
.banner_section { padding-top: 150px; }
.banner_section .banner_images { margin-top: 200px; }
.banner_section .banner_text h1 { font-size: 45px; }    
.features_section .feature_detail .feature_box {width: 350px;}

/* hero banner shape animation */
.banner_section .banner_shape1 { top: 0px; left: -3%;}
.banner_section .banner_shape2 { top: 100px; right: -2%; }
.banner_section .banner_shape3 { top: 400px; right: -4%; }

    }

    @media screen and (max-width:1200px) {
        .banner_section .banner_shape1 { top: -30px; left: -2%;}
    .banner_section .banner_shape2 { top: 100px; right: -2%; }
    .banner_section .banner_shape3 { top: 400px; right: -4%; }


    }

    @media screen and (max-width:992px) {

    /* banner section */
    .banner_section {text-align: center;}
    .banner_section .app_btn , .banner_section .used_app {justify-content: center;}

 /* banner section */
 .banner_section .banner_text h1 {font-size: 40px;}
    .banner_section .banner_slider {margin-top: 50px;}
    .banner_section .banner_slider .left_icon {left: 0;}
    .banner_section .banner_slider .right_icon {right: 0;}

    .banner_section .banner_images { margin: 100px 0 0 0; }
    .banner_section .banner_images .banner_screen.screen2 { margin-top: 100px; }
    .banner_section .banner_images .banner_screen.screen4 { margin-top: 100px; }
    }

    @media screen and (max-width:767px) {

        body {font-size: 14px; text-align: center;}
    .row_am {padding: 30px 0;}
    .section_title p br {display: none;}
    .free_app_section .container .free_app_inner .free_text .section_title h2, .section_title h2 {font-size: 24px;}

    /* banner section */
    .banner_section {margin-top: 0px;}
    .banner_section .banner_images { margin: 150px 0 0 0; }
    .banner_section .banner_text h1 {font-size: 30px;}
    .banner_section .app_btn , .banner_section .used_app {justify-content: center;}
    .banner_section .app_btn li:last-child {margin-left: 0;}
    .banner_section .app_btn li {margin: 0 15px;}
    .banner_section .app_btn li a {padding: 10px 20px;}
    .banner_section .app_btn li a img {height: 25px;}
    .banner_section .banner_slider .left_icon {left: -40px;}
    .banner_section .banner_slider .right_icon {right: -40px; z-index: 1000;}
    .banner_section .banner_slider::before {width: 350px; height: 350px;}
    .banner_section { padding-top:125px;  }
    .banner_section .banner_text p { padding: 10px 20px; }
    .banner_section .banner_images .banner_screen.screen3 { margin-top: 20px; }
    .banner_section .banner_images .banner_screen.screen4 { margin-top: 20px; }

    .banner_section .banner_images .banner_screen.screen2 { margin-top: 20px; }
    .banner_section .banner_images .banner_screen.screen4 { margin-top: 20px; }

    /* hero banner shape animation */
    .banner_section .banner_shape1, .banner_shape2, .banner_shape3 { display: none;}

    }

@media screen and (max-width:640px) {
    .banner_shape1, .banner_shape2, .banner_shape3 {  display: none;}
    

    }


 `;