import styled from "styled-components";
export const DownloadSectionStyle = styled.div`
  /* -----------Download_App_Section-Start------------------ */

  /* download app wraper */

  .free_app_section {
    padding-top: 70px;
    position: relative;
  }
  .free_img .imgOne{
    width: 255px;
    height: 519px;
  }
  .free_img .imgTwo{
    width: 317px;
    height: 646px;
  }


  .free_app_section .container .free_app_inner {
    background-color: #FFBB02;
    border-radius: 30px;
    padding: 20px 100px;
    padding-bottom: 50px;
    position: relative;
    z-index: 999999;
   
  }

  /* download app dark background */
  .free_app_section .container .free_app_inner .dark_bg {
    overflow: hidden;
  }

  .free_app_section .container .free_app_inner .dark_bg span {
    z-index: 9999;
  }

  .free_app_section .container .free_app_inner .row {
    align-items: center;
  }

  .free_app_section .container .free_app_inner .free_text .section_title {
    text-align: left;
  }

  /* download app heading h2 */
  .free_app_section .container .free_app_inner .free_text .section_title h2 {
    margin-bottom: 20px;
  }

  .free_app_section .container .free_app_inner .free_text .section_title h2,
  .free_app_section .container .free_app_inner .free_text .section_title p {
    color: var(--text-white);
  }

  .free_app_section .container .free_app_inner .free_text .app_btn {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  .free_app_section .container .free_app_inner .free_text .app_btn li a {
    display: block;
    position: relative;
    border-radius: 13px;
    transition: 0.4s all;
  }
  .app_btn li a .appstore-btn{
    width: 150px  ;
  }


 
  .free_app_section .container .free_app_inner .free_img {
    display: flex;
    align-items: center;
    margin-top: -120px;
  }

  .free_app_section .container .free_app_inner .free_img img:last-child {
    margin-left: -75px;
  }

  .purple_backdrop {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  background-color:  rgb(0,64,0);
  width: 100%;
  height: 100%;
  z-index: 99998;
  opacity: 0;
  transition: 0.4s all;
  pointer-events: none;
}

.purple_backdrop.visible {
  opacity: 1;
  pointer-events: auto;
}

  /* ------Media-Query-Start--------- */

  @media screen and (max-width: 1200px) {
    .row_am {
      padding: 50px 0;
    }

    /* download app section */
    .free_app_section .container .free_app_inner .free_img img {
      transform: scale(0.8);
    }
    .free_app_section .container .free_app_inner .free_img img:last-child {
      margin-left: -120px;
    }
    .free_app_section .container .free_app_inner .free_img {
      margin-top: -180px;
    }
  }

  @media screen and (max-width: 992px) {
    /* download app section */
    .free_app_section .container .free_app_inner {
      padding: 20px 15px;
    }
    .free_app_section .container .free_app_inner .free_img img {
      transform: scale(0.7);
    }
    .free_app_section .container .free_app_inner .free_img img:last-child {
      margin-left: -180px;
    }
    .free_app_section .container .free_app_inner .free_text .app_btn li a {
      /* padding: 10px 10px; */
    }
  }

  @media screen and (max-width: 767px) {
    body {
      font-size: 14px;
      text-align: center;
    }
    .row_am {
      padding: 30px 0;
    }
    .section_title p br {
      display: none;
    }
    .free_app_section .container .free_app_inner .free_text .section_title h2,
    .section_title h2 {
      font-size: 24px;
    }

    /* download section */
    .free_app_section .container .free_app_inner {
      padding: 50px 15px;
      padding-bottom: 0;
      z-index: 99;
    }
    .free_app_section .container .free_app_inner .free_img {
      margin-top: 0;
      justify-content: center;
    }
    .free_app_section .container .free_app_inner .free_img img {
      max-width: 100%;
    }
    .free_app_section .container .free_app_inner .free_text .section_title {
      text-align: center;
    }
    .free_app_section .container .free_app_inner .free_text .app_btn {
      flex-direction: column;
    }
    .free_app_section .container .free_app_inner .free_text .app_btn li a {
      width: 200px;
      text-align: center;
    }
    .free_app_section
      .container
      .free_app_inner
      .free_text
      .app_btn
      li:last-child {
      margin-left: 0;
      margin-top: 15px;
    }

    /* animation line section */
    .anim_line,
    .anim_line.dark_bg {
      width: 140%;
    }

    /* latest story section */
    .latest_story .story_box .story_img img {
      width: 100%;
    }

  

    .purple_backdrop {
      display: none;
    }
  }
  @media screen and (min-width:1200px) {
    .container {
        max-width: 1170px;
    }
}

`;
