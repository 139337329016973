import React from "react";
import { WorkesSectionStyle } from "./style";

import stepOne from "../../images/workesSection/stepOne.png"
import stepTwo from "../../images/workesSection/stepTwo.png"
import stepThree from "../../images/workesSection/stepThree.png"
import stepFour from "../../images/workesSection/stepFour.png"
import stepFive from "../../images/workesSection/stepFive.png"
import stepSix from "../../images/workesSection/stepSix.png"





const WorkesSection = () => {
  return (
    <WorkesSectionStyle>
      {/* How-It-Workes-Section-Start */}
      <section className="row_am how_it_works" id="how_it_work">
        {/* container start */}
        <div className="container">
          <div className="how_it_inner">
            <div
              className="section_title"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-delay={300}
            >
              {/* h2 */}
              <h2>
                <span>How it works</span> - 6 easy steps
              </h2>
              {/* p */}
              <p>
              Download the app, create a free account, and start enjoying a seamless experience.
              </p>
            </div>
            <div className="step_block">
              {/* UL */}
              <ul>
                {/* step */}
                <li>
                  <div
                    className="step_text"
                    // data-aos="fade-right"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <h4>Login Account</h4>
                    <p>Allowing users to sign in using their email or social media accounts. This seamless process helps you access your account and personalized features with ease.</p>
                  </div>
                  <div className="step_number">
                    <h3>01</h3>
                  </div>
                  <div
                    className="step_img"
                    // data-aos="fade-left"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <img src={stepOne} alt="image" />
                  </div>
                </li>
                {/* step */}
                <li>
                  <div
                    className="step_text"
                    // data-aos="fade-left"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <h4>Homepage</h4>
                  
                    <p>
                    Users can view promotions, explore categories, and see trending and top-selling products. There’s also quick access to notifications and the cart for easy shopping.
                    </p>
                  </div>
                  <div className="step_number">
                    <h3>02</h3>
                  </div>
                  <div
                    className="step_img"
                    // data-aos="fade-right"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                   <img src={stepTwo} alt="image" />
                  </div>
                </li>
                {/* step */}
                <li>
                  <div
                    className="step_text"
                    // data-aos="fade-right"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <h4>Product Detail</h4>
                    <p>
                    Users can view detailed information, high-quality images, and specifications of a product. The page also includes options to add the item to the cart, check ratings, and read reviews.
                    </p>
                  </div>
                  <div className="step_number">
                    <h3>03</h3>
                  </div>
                  <div
                    className="step_img"
                    // data-aos="fade-left"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                     <img src={stepThree} alt="image" />
                  </div>
                </li>
                 {/* step */}
                 <li>
                  <div
                    className="step_text"
                    // data-aos="fade-right"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <h4>Cart</h4>
                    <p>
                    After clicking 'Add to Cart,' the product is saved in your cart for easy access. You can then proceed to checkout or continue shopping to add more items.
                    </p>
                  </div>
                  <div className="step_number">
                    <h3>04</h3>
                  </div>
                  <div
                    className="step_img"
                    // data-aos="fade-left"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                     <img src={stepFour} alt="image" />
                  </div>
                </li>
                 {/* step */}
                 <li>
                  <div
                    className="step_text"
                    // data-aos="fade-right"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <h4>Checkout Flow</h4>
                    <p>
                    In the checkout process, review your cart items, enter payment details, and confirm your order. Once completed, you'll receive an order summary and confirmation.
                    </p>
                  </div>
                  <div className="step_number">
                    <h3>05</h3>
                  </div>
                  <div
                    className="step_img"
                    // data-aos="fade-left"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                     <img src={stepFive} alt="image" />
                  </div>
                </li>
                 {/* step */}
                 <li>
                  <div
                    className="step_text"
                    // data-aos="fade-right"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                    <h4>It’s done, enjoy the app</h4>
                    <p>
                    Congratulations! Your order has been successfully placed. You'll receive a confirmation email with the details and estimated delivery time. Thank you for shopping with us!
                    </p>
                  </div>
                  <div className="step_number">
                    <h3>06</h3>
                  </div>
                  <div
                    className="step_img"
                    // data-aos="fade-left"
                    data-aos="fade-up"
                    data-aos-duration={1500}
                  >
                     <img src={stepSix} alt="image" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* container end */}
      </section>
      {/* How-It-Workes-Section-end */}
    </WorkesSectionStyle>
  );
};

export default WorkesSection;
