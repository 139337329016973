import styled from "styled-components";
export const PrivacyPolicyStyle = styled.div`
.helloworld{
  color: red;
  list-style: circle;
  list-style-type: decimal;
}
.listStyle{
  padding-left: 25px;

}
.listStyle li{
  padding-top: 5px;
  list-style-type: disc;
  font-size: 16px;
}
.subheading{
  color: var(--red);
  font-size: 16px;
  font-weight: bold;
}



`;