import styled from "styled-components";
import tickicon from "../../images/modernUI/tickicon.png";
export const BeautifullUiDesignStyle = styled.div`
  /* -------------Modern-Ui-Section-Css-Start---------------- */
/* modern ui section wraper */
.modern_ui_section .row {
    align-items: center;
}

.modern_ui_section .design_block {
    margin-top: 45px;
}

/* modern ui text */
.modern_ui_section .section_title {
    text-align: left;
}

.modern_ui_section .ui_text {
    padding-right: 75px;
}

/* modern ui list */
.modern_ui_section .design_block li {
    padding-left: 40px;
    position: relative;
    margin-bottom: 25px;
}

.modern_ui_section .design_block li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 1px;
    background-image: url(${tickicon});
    width: 22px;
    height: 22px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.modern_ui_section .design_block li h4 {
    font-size: 20px;
    color: var(--dark-purple);
    font-weight: 600;
    margin-bottom: 8px;
}

.modern_ui_section .design_block li p {
    margin-bottom: 0;
}

/* modern ui images */
.modern_ui_section .ui_images {
    display: flex;
    position: relative;
}




.left_img img{
  width: 100%;
}



  @media screen and (min-width: 1200px) {
    .container {
      max-width: 1170px;
    }
  }




  /* ------Media-Query-Start--------- */



@media screen and (max-width:1200px) {

  .row_am {padding: 50px 0;}

}

@media screen and (max-width:992px) {
  /* modern ui section */
  .modern_ui_section .ui_images {margin-top: 10px;}
  .modern_ui_section .ui_text {padding-right: 0;}
  .ui_images .left_img{
    display: flex;
    justify-content: center;
  }
  


}

@media screen and (max-width:767px) {

  body {font-size: 14px; text-align: center;}
  .row_am {padding: 30px 0;}
  .section_title p br {display: none;}
  .section_title h2, .section_title h2 {font-size: 24px;}
/* modern ui section */
  .modern_ui_section .ui_images {margin-top: 10px;}
  .modern_ui_section .section_title {text-align: center;}
  .modern_ui_section .design_block li {padding-left: 0; margin-bottom: 30px;}
  .modern_ui_section .design_block li::before {position: relative; left: auto; top: auto; display: inline-block;}
  .modern_ui_section .ui_images img {max-width: 90%;}
  .modern_ui_section .ui_images .left_img {width: 100%;}

}

@media screen and (max-width:767px) {

}





`;
