import React from "react";
import mainLogo from "../../images/bannersectionImages/mainLogo.png";
import { ProductDetailStyle } from "./style";

import { useEffect, useState } from "react";
// import IosButton from "components/ios-button";
import Star from "../../images/products-details/star.png";
import CommentPic from "../../images/products-details/comment pic.png";
import Sofaimg from "../../images/products-details/sofa.png";
import Sofaimg2 from "../../images/products-details/sofa.png";
import appstorebtn from "../../images/bannersectionImages/appstoreblue.png";
import googlebtn from "../../images/bannersectionImages/googlebtnblue.png";

// import ReportModal from "components/modal/report-modal";
import useHooks from "./useHook";
import { useParams } from "react-router-dom";
// import Spiner from "components/spinner";
// import LotusPro from "assets/img/common/lotuspro.png";

const ProductDetail = () => {
  const { id } = useParams();

  //   const ProductReviews = [
  //     {
  //       id: 1,
  //       img: CommentPic,
  //       name: "Waleed Kashif",
  //       rating: "4.5",
  //       date: "November 14,2023 at 1:03pm",
  //       comment:
  //         "Lorem ipsum dolor sit amet consectetur. Viverra faucibus ultricies placerat orci montes. Bibendum posuere sed risus amet. Magnis quis eu vel laoreet sit ac. Augue metus augue sodales tincidunt non dictum arcu proin.",
  //     },
  //     {
  //       id: 1,
  //       img: CommentPic,
  //       name: "Waleed Kashif",
  //       rating: "4.5",
  //       date: "November 14,2023 at 1:03pm",
  //       comment:
  //         "Lorem ipsum dolor sit amet consectetur. Viverra faucibus ultricies placerat orci montes. Bibendum posuere sed risus amet. Magnis quis eu vel laoreet sit ac. Augue metus augue sodales tincidunt non dictum arcu proin.",
  //       sofaimg: Sofaimg,
  //       sofaimg2: Sofaimg2,
  //     },
  //     {
  //       id: 1,
  //     //   img: CommentPic,
  //       name: "Waleed Kashif",
  //       rating: "4.5",
  //       date: "November 14,2023 at 1:03pm",
  //       comment:
  //         "Lorem ipsum dolor sit amet consectetur. Viverra faucibus ultricies placerat orci montes. Bibendum posuere sed risus amet. Magnis quis eu vel laoreet sit ac. Augue metus augue sodales tincidunt non dictum arcu proin.",
  //     },
  //   ];
  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);

    // Options for formatting the date
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true, // for 12-hour format with am/pm
    };

    // Format the date according to options
    const formattedDate = date.toLocaleDateString("en-US", options);

    return formattedDate;
  };

  const [reportComment, setReportComment] = useState(false);
  const [selectedColor, setSelectedColor] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showAllReviews, setShowAllReviews] = useState(false);

  const handleSeeAllClick = () => {
    setShowAllReviews(true);
  };

  // API hook
  const { getproductsdetails, fetchproductsreviews } = useHooks();
  const [productsDetails, setProductsDetails] = useState([]);
  const [reviews, setReviews] = useState();
  useEffect(() => {
    const getproductsdetail = async () => {
      try {
        const response = await getproductsdetails(id);
        if (response.status === true) {
          setProductsDetails(response.data);
          setSelectedColor(response.data.colors[0]); // Set default selected color
        } else {
          console.log("Response status is false");
        }
      } catch (error) {
        console.error("Error fetching product details:", error);
      } finally {
        setLoading(false);
      }
    };
    getproductsdetail();
  }, [id]);

  const handleColorClick = (color) => {
    setSelectedColor(color);
  };

  const HandleReportsSection = () => {
    setReportComment(true);
    // console.log("clicked");
  };

  // Products Reviews

  const [productReviews, setProductReviews] = useState([]);
  const getproductsreviews = async () => {
    const params = {
      productId: id,
      page: 1,
      perPage: 20,
    };
    // console.log("params",params)
    try {
      const response = await fetchproductsreviews(params);
      if (response.status === true) {
        setProductReviews(response.data.results);
        console.log("hhahahahahahahehehhe", response);
        // setProductsDetails(response.data);
        // setSelectedColor(response.data.colors[0]); // Set default selected color
      } else {
        console.log("Response status is false");
      }
    } catch (error) {
      // console.error("Error fetching product details:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getproductsreviews();
  }, []);
  return (
    <ProductDetailStyle>
      <div className="section-main">
        <header>
          <div className="container">
            <nav className="navbar navbar-expand-lg">
              <div className="container-fluid ">
                <div className="navLogo">
                  <a className="navbar-brand" href="#">
                    <img src={mainLogo} />
                  </a>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <div className="row_am product_section">
          <div className="container">
            <div className="product_inner">
              <div className="heading-flex">
                <div className="padding-text">
                  <p className="heading m-0">Product Detail</p>
                </div>
              </div>

              <div
                className="card"
                style={{ borderRadius: "20px", border: "0px" }}
              >
                <div className="card-body">
                  <p className="name">{productsDetails.name}</p>
                  <div className="card-main-flex">
                    <div>
                      {selectedColor ? (
                        <img
                          src={`${process.env.REACT_APP_BASE_URL}/${selectedColor.image}`}
                          alt={selectedColor.colorName}
                          className="product-img"
                        />
                      ) : (
                        <p>No color images available</p>
                      )}
                    </div>

                    <table
                      className="table table-borderless"
                      style={{ height: "0px" }}
                    >
                      <div className="parent">
                        <div className="div1">
                          <td className="table-heading">Category</td>
                          <td className="table-data">
                            {productsDetails.category
                              ? productsDetails.category.name
                              : "Category Not Available"}
                          </td>
                          <td className="padding-rows table-heading">
                            Collection
                          </td>
                          <td className="table-data">
                            {productsDetails.collections &&
                            productsDetails.collections.length > 0 ? (
                              <div>
                                {productsDetails.collections.map(
                                  (collection) => (
                                    <div key={collection.id}>
                                      <p>{collection.name}</p>
                                    </div>
                                  )
                                )}
                              </div>
                            ) : (
                              "Collection Not Available"
                            )}
                          </td>
                        </div>

                        <div className="div2">
                          <td className="table-heading">Rating</td>
                          <td className="table-data">
                            {productsDetails.totalRatting}{" "}
                            <img src={Star} alt="" className="star-width" />
                          </td>
                          <td className="padding-rows table-heading">
                            Material
                          </td>
                          <td className="table-data" colSpan="3">
                            {productsDetails.materials &&
                            productsDetails.materials.length > 0
                              ? productsDetails.materials.map(
                                  (material, index) => (
                                    <span key={material.id}>
                                      {material.material
                                        ? material.material.name
                                        : "Material Name Not Available"}
                                      {index <
                                        productsDetails.materials.length - 1 &&
                                        ", "}
                                    </span>
                                  )
                                )
                              : "Materials Not Available"}
                          </td>
                        </div>

                        <div className="div3">
                          <td className="table-heading">Price</td>
                          <td className="table-data">
                            {productsDetails.productPrice}
                          </td>
                          <td className="padding-rows table-heading">
                            Sale Price
                          </td>
                          <td className="table-data">
                            {productsDetails.salePrice}
                          </td>
                        </div>
                        <div className="div4">
                          <td className="table-heading">Product SKU</td>
                          <td className="table-data">{productsDetails.sku}</td>
                          <td className="padding-rows table-heading">
                            Product Tags
                          </td>
                          <td className="table-data">
                            {productsDetails.metas &&
                            productsDetails.metas.length > 0
                              ? productsDetails.metas
                                  .map((meta) => meta.name)
                                  .join(", ")
                              : "No metas available"}
                          </td>
                        </div>
                      </div>
                    </table>
                  </div>

                  <div className="dimnetion-parent">
                    <div className="dimention">
                      <p className="table-headings">Dimentions</p>
                      <td className="table-data">
                        {productsDetails.length} x {productsDetails.width} x{" "}
                        {productsDetails.height} {productsDetails.unit} (L x W x
                        H)
                      </td>
                    </div>
                    <div className="dimention-flex">
                      <p className="table-heading">Colors</p>
                      <td className="table-data " style={{ display: "flex" }}>
                        {productsDetails.colors &&
                        productsDetails.colors.length > 0
                          ? productsDetails.colors.map((color) => (
                              <p
                                key={color.id}
                                style={{
                                  backgroundColor: color.colorCode,
                                  width: "22px",
                                  height: "22px",
                                  cursor: "pointer",
                                }}
                                className="color"
                                onClick={() => handleColorClick(color)}
                              ></p>
                            ))
                          : "No colors available"}
                        &nbsp;
                      </td>
                    </div>
                  </div>

                  <div className="product-flex">
                    <div className="div5">
                      <td className="table-heading">Product Images</td>
                    </div>
                    <div className="div6">
                      <td className="table-data product-img-flex">
                        {productsDetails &&
                        productsDetails.images &&
                        productsDetails.images.length > 0 ? (
                          productsDetails.images.map((image, index) => (
                            <img
                              key={index}
                              src={`${process.env.REACT_APP_BASE_URL}/${image.url}`}
                              alt={`Product Image ${index + 1}`}
                              className="image-size"
                            />
                          ))
                        ) : (
                          <div>No images available</div>
                        )}
                      </td>
                    </div>
                  </div>

                  <div className="parent-flex">
                    <p className="m-0 table-heading">Product Description</p>
                    <p className="description">{productsDetails.description}</p>
                  </div>
                  <div className="download_section">
                    <ul className="app_btn" >
                      <li>
                        <a className="app_store" >
                          <img className="blue_img" src={appstorebtn}/>
                        </a>
                      </li>
                      <li>
                        <a className="app_store">
                        <img className="blue_img" src={googlebtn}/>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ProductDetailStyle>
  );
};

export default ProductDetail;
