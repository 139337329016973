// In this file, you will be putting the styling which you are going to use globally in your application which leads to the reusability of the styling

import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

*{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: var(--Poppins-family);
        
    }



/* -----------Css-variable------ */

:root {
  --light-purple: #eff5f7;
  --red: #FFBB02;
    --bg-red: #E2383A;
    --dark-purple: #0C3A2D;
    --body-text-purple: #1e5e3b;
    --text-white: #ffffff;
    --bg-white: #ffffff;
    --slider-dots-color: #D4D2DD;
    --light-bg: #eff5f7;
    --Poppins-family:'Poppins', sans-serif;
}


.text-color{
    color: #E2383A;
}

/* ------Common-Css------------- */

html{scroll-behavior:smooth}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.7;
    font-family: 'Poppins', sans-serif;
    color: var(--body-text-purple);
    background-color: var(--light-purple);

}

.page_wrapper {
    width: 100%;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: var(--body-text-purple);
}

a:hover {
    text-decoration: none;
    color: var(--body-text-purple);
}

ul,
li {
    padding: 0;
    list-style-type: none;
    margin: 0;
}

button:focus,
.btn.focus,
.btn:focus {
    outline: none;
    box-shadow: none;
}

@media screen and (max-width:1200px) {
    .container {
        max-width: 100%;
    }
}

.section_title {
    text-align: center;
}

/* section heading h2 */
.section_title h2 {
    font-size: 40px;
    font-weight: 700;
    color: var( --dark-purple);
}

.section_title h2 span {
    color: var(--red);
}

.row_am {
    padding: 50px 0;
}

/* purple button */
.puprple_btn {
    background-color: var(--red);
    color: var(--text-white);
    border-radius: 50px;
    padding: 10px 40px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    font-weight: 500;
}

.puprple_btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-white);
    border-radius: 50px;
    transition: .6s all;
    z-index: -1;
}

.puprple_btn:hover::before {
    width: 100%;
}

.puprple_btn:hover {
    color: var(--red);
}

/* white button */
.white_btn {
    padding: 10px 45px;
    border: 1px solid var(--red);
    color: var(--purple);
    border-radius: 50px;
    background-color: var(--bg-white);
    font-weight: 700;
    position: relative;
    z-index: 1;
    overflow: hidden;
    font-weight: 500;
}

.white_btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0%;
    height: 100%;
    background-color: var(--bg-red);
    border-radius: 50px;
    transition: .6s all;
    z-index: -1;
}

.white_btn:hover::before {
    width: 110%;
}

.white_btn:hover {
    color: var(--text-white);
}

.highlited_block .white_btn:hover {
    border-color: var(--bg-white);
}


/* footer go top button */
.go_top {
    position: fixed;
    right: 30px;
    bottom: 75px;
    cursor: pointer;
    transition: .4s all;
    opacity: 0;
}

/* --------Object-Moving-Animation-Css-Start----- */
.moving_animation {
    animation: moving_object 6s infinite linear;
}

.moving_position_animatin {
    position: relative;
    animation: moving_position_animatin 6s infinite linear;
    z-index: -1;
}

.about_app_section .about_img .screen_img img {
    animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(3) {
    animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(2) {
    animation-delay: 3s;
}

.modern_ui_section .ui_images .right_img img:nth-child(1) {
    animation-delay: 3s;
}

@keyframes moving_object {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes moving_position_animatin {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-30px);
    }

    100% {
        transform: translateY(0);
    }
}


/* ------------Waves-Animation---------------- */
.waves-block {
    position: absolute;
    top: 52%;
    left: 47px;
    transform: translate(-50% , -50%);
    width: 150px;
    height: 150px;
}

.waves {
    width: 150px;
    height: 150px;
    background: rgba(255, 255, 255, 0.3);
    opacity: 0;
    /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";z */
    border-radius: 100%;
    z-index: -1;
    -webkit-animation: waves 3s ease-in-out infinite;
    animation: waves 3s ease-in-out infinite;
}

.wave-1 {
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
}

.wave-2 {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
    position: absolute;
    top: 0;
}

.wave-3 {
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
    position: absolute;
    top: 0;
}

@keyframes waves {
    0% {
        -webkit-transform: scale(0.2, 0.2);
        transform: scale(0.2, 0.2);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }

    50% {
        opacity: 0.9;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    }

    100% {
        -webkit-transform: scale(0.9, 0.9);
        transform: scale(0.9, 0.9);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    }
}



/*------Header Btn Animation------*/
@keyframes pulse-blue-small-sm {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	100%,
	30% {
		box-shadow: 0 0 0 12px transparent
	}
}

@keyframes pulse-blue-medium-sm {
	0% {
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
	}

	100%,
	30% {
		box-shadow: 0 0 0 20px transparent
	}
}


`;
