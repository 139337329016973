import styled from "styled-components";
export const BeautifullInterfaceStyle = styled.div`
  /* -----------Interface_Section-Css-Start----------------- */

  /* interface wraper */

  /* -----------Interface_Section-Css-Start----------------- */

  /* interface wraper */
  .screen_slider {
    margin-top: 45px;
    min-height: 720px;
  }

  /* interface images */
  .screen_frame_img img {
    transform: scale(0.9);
    /* border: 2px solid #000; */
    border-radius: 20px;
    transition: 1s all;
    margin: 0 auto;
  }

  .screen_frame_img img {
    height: 450px;
    transform: scale(1);
    /* border: 3px solid #000; */
  }

  /* ------Media-Query-Start--------- */

  @media screen and (max-width: 1200px) {
    .row_am {
      padding: 50px 0;
    }
  }

  @media screen and (max-width: 992px) {
    /* interface section */
    .interface_section .screen_slider {
      min-height: 400px;
    }
  }

  @media screen and (max-width: 767px) {
    body {
      font-size: 14px;
      text-align: center;
    }
    .row_am {
      padding: 30px 0;
    }
    .section_title p br {
      display: none;
    }
  


  }
  @media screen and (min-width:1200px) {
    .container {
        max-width: 1170px;
    }
}
`;
