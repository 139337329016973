import { DataApi } from "../../api/index";
const useHooks = () => {
  const getproductsdetails = async (id) => {
    try {
      const response = await DataApi.getproductsdetails(id);
      console.log(response, "hello");
      return response;
    } catch (error) {
      console.error(error);
      alert(error.message);
      return null;
    }
  };
  const fetchproductsreviews = async (params) => {
    try {
      const response = await DataApi.getproductsReviews(params);
      // console.log(response, "hello");
      return response;
    } catch (error) {
      console.error(error);
      // alert(error.message);
      return null;
    }
  };
  return { getproductsdetails, fetchproductsreviews };
};
export default useHooks;
