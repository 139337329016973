import React from "react";
import { LoaderStyle } from "./style";
const Loader = () => {
  return (
    <LoaderStyle>
      <div id="preloader">
        <div id="loader" />
      </div>
    </LoaderStyle>
  );
};

export default Loader;
