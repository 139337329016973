import styled from "styled-components";
export const TermsConditionStyle = styled.div`
.subheading{
  color: var(--red);
  font-size: 18px;
  font-weight: bold;
}



`;