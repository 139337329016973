import styled from "styled-components";

export const Main = styled.div`
  .spinner-adjust {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: backgroundFade 2s infinite;
  }

  .spinner {
    position: relative;
    width: 100px;
    height: 100px;

    z-index: 99;

    border-radius: 47px;
  }
  .absolute {
  }

  .sofa {
    position: absolute;
    top: 46%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: auto;
  }

  .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: auto;
    animation: rotate 2s linear infinite;
  }

  @keyframes rotate {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;
